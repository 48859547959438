/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter add_pb_suffix,eslint_disable,ts_nocheck,generate_dependencies,long_type_number
// @generated from protobuf file "flow/flow.proto" (package "flow", syntax proto3)
// tslint:disable
// @ts-nocheck
//
// SPDX-License-Identifier: Apache-2.0
// Copyright Authors of Hubble
//
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { UInt32Value } from "../google/protobuf/wrappers_pb";
import { Int32Value } from "../google/protobuf/wrappers_pb";
import { Any } from "../google/protobuf/any_pb";
import { BoolValue } from "../google/protobuf/wrappers_pb";
import { Timestamp } from "../google/protobuf/timestamp_pb";
/**
 * @generated from protobuf message flow.Flow
 */
export interface Flow {
    /**
     * @generated from protobuf field: google.protobuf.Timestamp time = 1;
     */
    time?: Timestamp;
    /**
     * uuid is a universally unique identifier for this flow.
     *
     * @generated from protobuf field: string uuid = 34;
     */
    uuid: string;
    /**
     * @generated from protobuf field: flow.Verdict verdict = 2;
     */
    verdict: Verdict;
    /**
     * only applicable to Verdict = DROPPED.
     * deprecated in favor of drop_reason_desc.
     *
     * @deprecated
     * @generated from protobuf field: uint32 drop_reason = 3 [deprecated = true];
     */
    dropReason: number;
    /**
     * auth_type is the authentication type specified for the flow in Cilium Network Policy.
     * Only set on policy verdict events.
     *
     * @generated from protobuf field: flow.AuthType auth_type = 35;
     */
    authType: AuthType;
    /**
     * l2
     *
     * @generated from protobuf field: flow.Ethernet ethernet = 4;
     */
    ethernet?: Ethernet;
    /**
     * l3
     *
     * @generated from protobuf field: flow.IP IP = 5 [json_name = "IP"];
     */
    iP?: IP;
    /**
     * l4
     *
     * @generated from protobuf field: flow.Layer4 l4 = 6;
     */
    l4?: Layer4;
    /**
     * @generated from protobuf field: flow.Endpoint source = 8;
     */
    source?: Endpoint;
    /**
     * @generated from protobuf field: flow.Endpoint destination = 9;
     */
    destination?: Endpoint;
    /**
     * @generated from protobuf field: flow.FlowType Type = 10 [json_name = "Type"];
     */
    type: FlowType;
    /**
     * NodeName is the name of the node from which this Flow was captured.
     *
     * @generated from protobuf field: string node_name = 11;
     */
    nodeName: string;
    /**
     * node labels in `foo=bar` format.
     *
     * @generated from protobuf field: repeated string node_labels = 37;
     */
    nodeLabels: string[];
    /**
     * all names the source IP can have.
     *
     * @generated from protobuf field: repeated string source_names = 13;
     */
    sourceNames: string[];
    /**
     * all names the destination IP can have.
     *
     * @generated from protobuf field: repeated string destination_names = 14;
     */
    destinationNames: string[];
    /**
     * L7 information. This field is set if and only if FlowType is L7.
     *
     * @generated from protobuf field: flow.Layer7 l7 = 15;
     */
    l7?: Layer7;
    /**
     * Deprecated. This suffers from false negatives due to protobuf not being
     * able to distinguish between the value being false or it being absent.
     * Please use is_reply instead.
     *
     * @deprecated
     * @generated from protobuf field: bool reply = 16 [deprecated = true];
     */
    reply: boolean;
    /**
     * EventType of the originating Cilium event
     *
     * @generated from protobuf field: flow.CiliumEventType event_type = 19;
     */
    eventType?: CiliumEventType;
    /**
     * source_service contains the service name of the source
     *
     * @generated from protobuf field: flow.Service source_service = 20;
     */
    sourceService?: Service;
    /**
     * destination_service contains the service name of the destination
     *
     * @generated from protobuf field: flow.Service destination_service = 21;
     */
    destinationService?: Service;
    /**
     * traffic_direction of the connection, e.g. ingress or egress
     *
     * @generated from protobuf field: flow.TrafficDirection traffic_direction = 22;
     */
    trafficDirection: TrafficDirection;
    /**
     * policy_match_type is only applicable to the cilium event type PolicyVerdict
     * https://github.com/cilium/cilium/blob/e831859b5cc336c6d964a6d35bbd34d1840e21b9/pkg/monitor/datapath_policy.go#L50
     *
     * @generated from protobuf field: uint32 policy_match_type = 23;
     */
    policyMatchType: number;
    /**
     * Only applicable to cilium trace notifications, blank for other types.
     *
     * @generated from protobuf field: flow.TraceObservationPoint trace_observation_point = 24;
     */
    traceObservationPoint: TraceObservationPoint;
    /**
     * Cilium datapath trace reason info.
     *
     * @generated from protobuf field: flow.TraceReason trace_reason = 36;
     */
    traceReason: TraceReason;
    /**
     * Cilium datapath filename and line number. Currently only applicable when
     * Verdict = DROPPED.
     *
     * @generated from protobuf field: flow.FileInfo file = 38;
     */
    file?: FileInfo;
    /**
     * only applicable to Verdict = DROPPED.
     *
     * @generated from protobuf field: flow.DropReason drop_reason_desc = 25;
     */
    dropReasonDesc: DropReason;
    /**
     * is_reply indicates that this was a packet (L4) or message (L7) in the
     * reply direction. May be absent (in which case it is unknown whether it
     * is a reply or not).
     *
     * @generated from protobuf field: google.protobuf.BoolValue is_reply = 26;
     */
    isReply?: BoolValue;
    /**
     * Only applicable to cilium debug capture events, blank for other types
     *
     * @generated from protobuf field: flow.DebugCapturePoint debug_capture_point = 27;
     */
    debugCapturePoint: DebugCapturePoint;
    /**
     * interface is the network interface on which this flow was observed
     *
     * @generated from protobuf field: flow.NetworkInterface interface = 28;
     */
    interface?: NetworkInterface;
    /**
     * proxy_port indicates the port of the proxy to which the flow was forwarded
     *
     * @generated from protobuf field: uint32 proxy_port = 29;
     */
    proxyPort: number;
    /**
     * trace_context contains information about a trace related to the flow, if
     * any.
     *
     * @generated from protobuf field: flow.TraceContext trace_context = 30;
     */
    traceContext?: TraceContext;
    /**
     * sock_xlate_point is the socket translation point.
     * Only applicable to TraceSock notifications, blank for other types
     *
     * @generated from protobuf field: flow.SocketTranslationPoint sock_xlate_point = 31;
     */
    sockXlatePoint: SocketTranslationPoint;
    /**
     * socket_cookie is the Linux kernel socket cookie for this flow.
     * Only applicable to TraceSock notifications, zero for other types
     *
     * @generated from protobuf field: uint64 socket_cookie = 32;
     */
    socketCookie: number;
    /**
     * cgroup_id of the process which emitted this event.
     * Only applicable to TraceSock notifications, zero for other types
     *
     * @generated from protobuf field: uint64 cgroup_id = 33;
     */
    cgroupId: number;
    /**
     * This is a temporary workaround to support summary field for pb.Flow without
     * duplicating logic from the old parser. This field will be removed once we
     * fully migrate to the new parser.
     *
     * @deprecated
     * @generated from protobuf field: string Summary = 100000 [deprecated = true, json_name = "Summary"];
     */
    summary: string;
    /**
     * extensions can be used to add arbitrary additional metadata to flows.
     * This can be used to extend functionality for other Hubble compatible
     * APIs, or experiment with new functionality without needing to change the public API.
     *
     * @generated from protobuf field: google.protobuf.Any extensions = 150000;
     */
    extensions?: Any;
    /**
     * The CiliumNetworkPolicies allowing the egress of the flow.
     *
     * @generated from protobuf field: repeated flow.Policy egress_allowed_by = 21001;
     */
    egressAllowedBy: Policy[];
    /**
     * The CiliumNetworkPolicies allowing the ingress of the flow.
     *
     * @generated from protobuf field: repeated flow.Policy ingress_allowed_by = 21002;
     */
    ingressAllowedBy: Policy[];
    /**
     * The CiliumNetworkPolicies denying the egress of the flow.
     *
     * @generated from protobuf field: repeated flow.Policy egress_denied_by = 21004;
     */
    egressDeniedBy: Policy[];
    /**
     * The CiliumNetworkPolicies denying the ingress of the flow.
     *
     * @generated from protobuf field: repeated flow.Policy ingress_denied_by = 21005;
     */
    ingressDeniedBy: Policy[];
}
/**
 * @generated from protobuf message flow.FileInfo
 */
export interface FileInfo {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: uint32 line = 2;
     */
    line: number;
}
/**
 * @generated from protobuf message flow.Layer4
 */
export interface Layer4 {
    /**
     * @generated from protobuf oneof: protocol
     */
    protocol: {
        oneofKind: "tCP";
        /**
         * @generated from protobuf field: flow.TCP TCP = 1 [json_name = "TCP"];
         */
        tCP: TCP;
    } | {
        oneofKind: "uDP";
        /**
         * @generated from protobuf field: flow.UDP UDP = 2 [json_name = "UDP"];
         */
        uDP: UDP;
    } | {
        oneofKind: "iCMPv4";
        /**
         * ICMP is technically not L4, but mutually exclusive with the above
         *
         * @generated from protobuf field: flow.ICMPv4 ICMPv4 = 3 [json_name = "ICMPv4"];
         */
        iCMPv4: ICMPv4;
    } | {
        oneofKind: "iCMPv6";
        /**
         * @generated from protobuf field: flow.ICMPv6 ICMPv6 = 4 [json_name = "ICMPv6"];
         */
        iCMPv6: ICMPv6;
    } | {
        oneofKind: "sCTP";
        /**
         * @generated from protobuf field: flow.SCTP SCTP = 5 [json_name = "SCTP"];
         */
        sCTP: SCTP;
    } | {
        oneofKind: undefined;
    };
}
/**
 * Message for L7 flow, which roughly corresponds to Cilium's accesslog [LogRecord](https://github.com/cilium/cilium/blob/728c79e427438ab6f8d9375b62fccd6fed4ace3a/pkg/proxy/accesslog/record.go#L141):
 *
 * @generated from protobuf message flow.Layer7
 */
export interface Layer7 {
    /**
     * @generated from protobuf field: flow.L7FlowType type = 1;
     */
    type: L7FlowType;
    /**
     * Latency of the response
     *
     * @generated from protobuf field: uint64 latency_ns = 2;
     */
    latencyNs: number;
    /**
     * @generated from protobuf oneof: record
     */
    record: {
        oneofKind: "dns";
        /**
         * @generated from protobuf field: flow.DNS dns = 100;
         */
        dns: DNS;
    } | {
        oneofKind: "http";
        /**
         * @generated from protobuf field: flow.HTTP http = 101;
         */
        http: HTTP;
    } | {
        oneofKind: "kafka";
        /**
         * @generated from protobuf field: flow.Kafka kafka = 102;
         */
        kafka: Kafka;
    } | {
        oneofKind: undefined;
    };
}
/**
 * TraceContext contains trace context propagation data, i.e. information about a
 * distributed trace.
 * For more information about trace context, check the [W3C Trace Context specification](https://www.w3.org/TR/trace-context/).
 *
 * @generated from protobuf message flow.TraceContext
 */
export interface TraceContext {
    /**
     * parent identifies the incoming request in a tracing system.
     *
     * @generated from protobuf field: flow.TraceParent parent = 1;
     */
    parent?: TraceParent;
}
/**
 * TraceParent identifies the incoming request in a tracing system.
 *
 * @generated from protobuf message flow.TraceParent
 */
export interface TraceParent {
    /**
     * trace_id is a unique value that identifies a trace. It is a byte array
     * represented as a hex string.
     *
     * @generated from protobuf field: string trace_id = 1;
     */
    traceId: string;
}
/**
 * @generated from protobuf message flow.Endpoint
 */
export interface Endpoint {
    /**
     * @generated from protobuf field: uint32 ID = 1 [json_name = "ID"];
     */
    iD: number;
    /**
     * @generated from protobuf field: uint32 identity = 2;
     */
    identity: number;
    /**
     * @generated from protobuf field: string cluster_name = 7;
     */
    clusterName: string;
    /**
     * @generated from protobuf field: string namespace = 3;
     */
    namespace: string;
    /**
     * labels in `foo=bar` format.
     *
     * @generated from protobuf field: repeated string labels = 4;
     */
    labels: string[];
    /**
     * @generated from protobuf field: string pod_name = 5;
     */
    podName: string;
    /**
     * @generated from protobuf field: repeated flow.Workload workloads = 6;
     */
    workloads: Workload[];
}
/**
 * @generated from protobuf message flow.Workload
 */
export interface Workload {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: string kind = 2;
     */
    kind: string;
}
/**
 * @generated from protobuf message flow.TCP
 */
export interface TCP {
    /**
     * @generated from protobuf field: uint32 source_port = 1;
     */
    sourcePort: number;
    /**
     * @generated from protobuf field: uint32 destination_port = 2;
     */
    destinationPort: number;
    /**
     * @generated from protobuf field: flow.TCPFlags flags = 3;
     */
    flags?: TCPFlags;
}
/**
 * @generated from protobuf message flow.IP
 */
export interface IP {
    /**
     * @generated from protobuf field: string source = 1;
     */
    source: string;
    /**
     * source_xlated is the post-translation source IP when the flow was
     * SNATed. When "source_xlated" is set, the "source" field is populated
     * with the pre-translation source IP address.
     *
     * @generated from protobuf field: string source_xlated = 5;
     */
    sourceXlated: string;
    /**
     * @generated from protobuf field: string destination = 2;
     */
    destination: string;
    /**
     * @generated from protobuf field: flow.IPVersion ipVersion = 3;
     */
    ipVersion: IPVersion;
    /**
     * This field indicates whether the TraceReasonEncryptMask is set or not.
     * https://github.com/cilium/cilium/blob/ba0ed147bd5bb342f67b1794c2ad13c6e99d5236/pkg/monitor/datapath_trace.go#L27
     *
     * @generated from protobuf field: bool encrypted = 4;
     */
    encrypted: boolean;
}
/**
 * @generated from protobuf message flow.Ethernet
 */
export interface Ethernet {
    /**
     * @generated from protobuf field: string source = 1;
     */
    source: string;
    /**
     * @generated from protobuf field: string destination = 2;
     */
    destination: string;
}
/**
 * @generated from protobuf message flow.TCPFlags
 */
export interface TCPFlags {
    /**
     * @generated from protobuf field: bool FIN = 1 [json_name = "FIN"];
     */
    fIN: boolean;
    /**
     * @generated from protobuf field: bool SYN = 2 [json_name = "SYN"];
     */
    sYN: boolean;
    /**
     * @generated from protobuf field: bool RST = 3 [json_name = "RST"];
     */
    rST: boolean;
    /**
     * @generated from protobuf field: bool PSH = 4 [json_name = "PSH"];
     */
    pSH: boolean;
    /**
     * @generated from protobuf field: bool ACK = 5 [json_name = "ACK"];
     */
    aCK: boolean;
    /**
     * @generated from protobuf field: bool URG = 6 [json_name = "URG"];
     */
    uRG: boolean;
    /**
     * @generated from protobuf field: bool ECE = 7 [json_name = "ECE"];
     */
    eCE: boolean;
    /**
     * @generated from protobuf field: bool CWR = 8 [json_name = "CWR"];
     */
    cWR: boolean;
    /**
     * @generated from protobuf field: bool NS = 9 [json_name = "NS"];
     */
    nS: boolean;
}
/**
 * @generated from protobuf message flow.UDP
 */
export interface UDP {
    /**
     * @generated from protobuf field: uint32 source_port = 1;
     */
    sourcePort: number;
    /**
     * @generated from protobuf field: uint32 destination_port = 2;
     */
    destinationPort: number;
}
/**
 * @generated from protobuf message flow.SCTP
 */
export interface SCTP {
    /**
     * @generated from protobuf field: uint32 source_port = 1;
     */
    sourcePort: number;
    /**
     * @generated from protobuf field: uint32 destination_port = 2;
     */
    destinationPort: number;
}
/**
 * @generated from protobuf message flow.ICMPv4
 */
export interface ICMPv4 {
    /**
     * @generated from protobuf field: uint32 type = 1;
     */
    type: number;
    /**
     * @generated from protobuf field: uint32 code = 2;
     */
    code: number;
}
/**
 * @generated from protobuf message flow.ICMPv6
 */
export interface ICMPv6 {
    /**
     * @generated from protobuf field: uint32 type = 1;
     */
    type: number;
    /**
     * @generated from protobuf field: uint32 code = 2;
     */
    code: number;
}
/**
 * @generated from protobuf message flow.Policy
 */
export interface Policy {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: string namespace = 2;
     */
    namespace: string;
    /**
     * @generated from protobuf field: repeated string labels = 3;
     */
    labels: string[];
    /**
     * @generated from protobuf field: uint64 revision = 4;
     */
    revision: number;
    /**
     * @generated from protobuf field: string kind = 5;
     */
    kind: string;
}
/**
 * EventTypeFilter is a filter describing a particular event type.
 *
 * @generated from protobuf message flow.EventTypeFilter
 */
export interface EventTypeFilter {
    /**
     * type is the primary flow type as defined by:
     * github.com/cilium/cilium/pkg/monitor/api.MessageType*
     *
     * @generated from protobuf field: int32 type = 1;
     */
    type: number;
    /**
     * match_sub_type is set to true when matching on the sub_type should
     * be done. This flag is required as 0 is a valid sub_type.
     *
     * @generated from protobuf field: bool match_sub_type = 2;
     */
    matchSubType: boolean;
    /**
     * sub_type is the secondary type, e.g.
     * - github.com/cilium/cilium/pkg/monitor/api.Trace*
     *
     * @generated from protobuf field: int32 sub_type = 3;
     */
    subType: number;
}
/**
 * CiliumEventType from which the flow originated.
 *
 * @generated from protobuf message flow.CiliumEventType
 */
export interface CiliumEventType {
    /**
     * type of event the flow originated from, i.e.
     * github.com/cilium/cilium/pkg/monitor/api.MessageType*
     *
     * @generated from protobuf field: int32 type = 1;
     */
    type: number;
    /**
     * sub_type may indicate more details depending on type, e.g.
     * - github.com/cilium/cilium/pkg/monitor/api.Trace*
     * - github.com/cilium/cilium/pkg/monitor/api.Drop*
     * - github.com/cilium/cilium/pkg/monitor/api.DbgCapture*
     *
     * @generated from protobuf field: int32 sub_type = 2;
     */
    subType: number;
}
/**
 * FlowFilter represent an individual flow filter. All fields are optional. If
 * multiple fields are set, then all fields must match for the filter to match.
 *
 * @generated from protobuf message flow.FlowFilter
 */
export interface FlowFilter {
    /**
     * uuid filters by a list of flow uuids.
     *
     * @generated from protobuf field: repeated string uuid = 29;
     */
    uuid: string[];
    /**
     * source_ip filters by a list of source ips. Each of the source ips can be
     * specified as an exact match (e.g. "1.1.1.1") or as a CIDR range (e.g.
     * "1.1.1.0/24").
     *
     * @generated from protobuf field: repeated string source_ip = 1;
     */
    sourceIp: string[];
    /**
     * source_ip_xlated filters by a list IPs. Each of the IPs can be specified
     * as an exact match (e.g. "1.1.1.1") or as a CIDR range (e.g.
     * "1.1.1.0/24").
     *
     * @generated from protobuf field: repeated string source_ip_xlated = 34;
     */
    sourceIpXlated: string[];
    /**
     * source_pod filters by a list of source pod name prefixes, optionally
     * within a given namespace (e.g. "xwing", "kube-system/coredns-").
     * The pod name can be omitted to only filter by namespace
     * (e.g. "kube-system/") or the namespace can be omitted to filter for
     * pods in any namespace (e.g. "/xwing")
     *
     * @generated from protobuf field: repeated string source_pod = 2;
     */
    sourcePod: string[];
    /**
     * source_fqdn filters by a list of source fully qualified domain names
     *
     * @generated from protobuf field: repeated string source_fqdn = 7;
     */
    sourceFqdn: string[];
    /**
     * source_labels filters on a list of source label selectors. Selectors
     * support the full Kubernetes label selector syntax.
     *
     * @generated from protobuf field: repeated string source_label = 10;
     */
    sourceLabel: string[];
    /**
     * source_service filters on a list of source service names. This field
     * supports the same syntax as the source_pod field.
     *
     * @generated from protobuf field: repeated string source_service = 16;
     */
    sourceService: string[];
    /**
     * source_workload filters by a list of source workload.
     *
     * @generated from protobuf field: repeated flow.Workload source_workload = 26;
     */
    sourceWorkload: Workload[];
    /**
     * source_cluster_name filters by a list of source cluster names.
     *
     * @generated from protobuf field: repeated string source_cluster_name = 37;
     */
    sourceClusterName: string[];
    /**
     * destination_ip filters by a list of destination ips. Each of the
     * destination ips can be specified as an exact match (e.g. "1.1.1.1") or
     * as a CIDR range (e.g. "1.1.1.0/24").
     *
     * @generated from protobuf field: repeated string destination_ip = 3;
     */
    destinationIp: string[];
    /**
     * destination_pod filters by a list of destination pod names
     *
     * @generated from protobuf field: repeated string destination_pod = 4;
     */
    destinationPod: string[];
    /**
     * destination_fqdn filters by a list of destination fully qualified domain names
     *
     * @generated from protobuf field: repeated string destination_fqdn = 8;
     */
    destinationFqdn: string[];
    /**
     * destination_label filters on a list of destination label selectors
     *
     * @generated from protobuf field: repeated string destination_label = 11;
     */
    destinationLabel: string[];
    /**
     * destination_service filters on a list of destination service names
     *
     * @generated from protobuf field: repeated string destination_service = 17;
     */
    destinationService: string[];
    /**
     * destination_workload filters by a list of destination workload.
     *
     * @generated from protobuf field: repeated flow.Workload destination_workload = 27;
     */
    destinationWorkload: Workload[];
    /**
     * destination_cluster_name filters by a list of destination cluster names.
     *
     * @generated from protobuf field: repeated string destination_cluster_name = 38;
     */
    destinationClusterName: string[];
    /**
     * traffic_direction filters flow by direction of the connection, e.g.
     * ingress or egress.
     *
     * @generated from protobuf field: repeated flow.TrafficDirection traffic_direction = 30;
     */
    trafficDirection: TrafficDirection[];
    /**
     * only return Flows that were classified with a particular verdict.
     *
     * @generated from protobuf field: repeated flow.Verdict verdict = 5;
     */
    verdict: Verdict[];
    /**
     * only applicable to Verdict = DROPPED (e.g. "POLICY_DENIED", "UNSUPPORTED_L3_PROTOCOL")
     *
     * @generated from protobuf field: repeated flow.DropReason drop_reason_desc = 33;
     */
    dropReasonDesc: DropReason[];
    /**
     * interface is the network interface on which this flow was observed.
     *
     * @generated from protobuf field: repeated flow.NetworkInterface interface = 35;
     */
    interface: NetworkInterface[];
    /**
     * event_type is the list of event types to filter on
     *
     * @generated from protobuf field: repeated flow.EventTypeFilter event_type = 6;
     */
    eventType: EventTypeFilter[];
    /**
     * http_status_code is a list of string prefixes (e.g. "4+", "404", "5+")
     * to filter on the HTTP status code
     *
     * @generated from protobuf field: repeated string http_status_code = 9;
     */
    httpStatusCode: string[];
    /**
     * protocol filters flows by L4 or L7 protocol, e.g. (e.g. "tcp", "http")
     *
     * @generated from protobuf field: repeated string protocol = 12;
     */
    protocol: string[];
    /**
     * source_port filters flows by L4 source port
     *
     * @generated from protobuf field: repeated string source_port = 13;
     */
    sourcePort: string[];
    /**
     * destination_port filters flows by L4 destination port
     *
     * @generated from protobuf field: repeated string destination_port = 14;
     */
    destinationPort: string[];
    /**
     * reply filters flows based on the direction of the flow.
     *
     * @generated from protobuf field: repeated bool reply = 15;
     */
    reply: boolean[];
    /**
     * dns_query filters L7 DNS flows by query patterns (RE2 regex), e.g. 'kube.*local'.
     *
     * @generated from protobuf field: repeated string dns_query = 18;
     */
    dnsQuery: string[];
    /**
     * source_identity filters by the security identity of the source endpoint.
     *
     * @generated from protobuf field: repeated uint32 source_identity = 19;
     */
    sourceIdentity: number[];
    /**
     * destination_identity filters by the security identity of the destination endpoint.
     *
     * @generated from protobuf field: repeated uint32 destination_identity = 20;
     */
    destinationIdentity: number[];
    /**
     * GET, POST, PUT, etc. methods. This type of field is well suited for an
     * enum but every single existing place is using a string already.
     *
     * @generated from protobuf field: repeated string http_method = 21;
     */
    httpMethod: string[];
    /**
     * http_path is a list of regular expressions to filter on the HTTP path.
     *
     * @generated from protobuf field: repeated string http_path = 22;
     */
    httpPath: string[];
    /**
     * http_url is a list of regular expressions to filter on the HTTP URL.
     *
     * @generated from protobuf field: repeated string http_url = 31;
     */
    httpUrl: string[];
    /**
     * http_header is a list of key:value pairs to filter on the HTTP headers.
     *
     * @generated from protobuf field: repeated flow.HTTPHeader http_header = 32;
     */
    httpHeader: HTTPHeader[];
    /**
     * tcp_flags filters flows based on TCP header flags
     *
     * @generated from protobuf field: repeated flow.TCPFlags tcp_flags = 23;
     */
    tcpFlags: TCPFlags[];
    /**
     * node_name is a list of patterns to filter on the node name, e.g. "k8s*",
     * "test-cluster/*.domain.com", "cluster-name/" etc.
     *
     * @generated from protobuf field: repeated string node_name = 24;
     */
    nodeName: string[];
    /**
     * node_labels filters on a list of node label selectors. Selectors support
     * the full Kubernetes label selector syntax.
     *
     * @generated from protobuf field: repeated string node_labels = 36;
     */
    nodeLabels: string[];
    /**
     * filter based on IP version (ipv4 or ipv6)
     *
     * @generated from protobuf field: repeated flow.IPVersion ip_version = 25;
     */
    ipVersion: IPVersion[];
    /**
     * trace_id filters flows by trace ID
     *
     * @generated from protobuf field: repeated string trace_id = 28;
     */
    traceId: string[];
    /**
     * experimental contains filters that are not stable yet. Support for
     * experimental features is always optional and subject to change.
     *
     * @generated from protobuf field: flow.FlowFilter.Experimental experimental = 999;
     */
    experimental?: FlowFilter_Experimental;
}
/**
 * Experimental contains filters that are not stable yet. Support for
 * experimental features is always optional and subject to change.
 *
 * @generated from protobuf message flow.FlowFilter.Experimental
 */
export interface FlowFilter_Experimental {
    /**
     * cel_expression takes a common expression language (CEL) expression
     * returning a boolean to determine if the filter matched or not.
     * You can use the `_flow` variable to access fields on the flow using
     * the flow.Flow protobuf field names.
     * See https://github.com/google/cel-spec/blob/v0.14.0/doc/intro.md#introduction
     * for more details on CEL and accessing the protobuf fields in CEL.
     * Using CEL has performance cost compared to other filters, so prefer
     * using non-CEL filters when possible, and try to specify CEL filters
     * last in the list of FlowFilters.
     *
     * @generated from protobuf field: repeated string cel_expression = 1;
     */
    celExpression: string[];
}
/**
 * DNS flow. This is basically directly mapped from Cilium's [LogRecordDNS](https://github.com/cilium/cilium/blob/04f3889d627774f79e56d14ddbc165b3169e2d01/pkg/proxy/accesslog/record.go#L264):
 *
 * @generated from protobuf message flow.DNS
 */
export interface DNS {
    /**
     * DNS name that's being looked up: e.g. "isovalent.com."
     *
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * List of IP addresses in the DNS response.
     *
     * @generated from protobuf field: repeated string ips = 2;
     */
    ips: string[];
    /**
     * TTL in the DNS response.
     *
     * @generated from protobuf field: uint32 ttl = 3;
     */
    ttl: number;
    /**
     * List of CNames in the DNS response.
     *
     * @generated from protobuf field: repeated string cnames = 4;
     */
    cnames: string[];
    /**
     * Corresponds to DNSDataSource defined in:
     *   https://github.com/cilium/cilium/blob/04f3889d627774f79e56d14ddbc165b3169e2d01/pkg/proxy/accesslog/record.go#L253
     *
     * @generated from protobuf field: string observation_source = 5;
     */
    observationSource: string;
    /**
     * Return code of the DNS request defined in:
     *   https://www.iana.org/assignments/dns-parameters/dns-parameters.xhtml#dns-parameters-6
     *
     * @generated from protobuf field: uint32 rcode = 6;
     */
    rcode: number;
    /**
     * String representation of qtypes defined in:
     *   https://tools.ietf.org/html/rfc1035#section-3.2.3
     *
     * @generated from protobuf field: repeated string qtypes = 7;
     */
    qtypes: string[];
    /**
     * String representation of rrtypes defined in:
     * https://www.iana.org/assignments/dns-parameters/dns-parameters.xhtml#dns-parameters-4
     *
     * @generated from protobuf field: repeated string rrtypes = 8;
     */
    rrtypes: string[];
}
/**
 * @generated from protobuf message flow.HTTPHeader
 */
export interface HTTPHeader {
    /**
     * @generated from protobuf field: string key = 1;
     */
    key: string;
    /**
     * @generated from protobuf field: string value = 2;
     */
    value: string;
}
/**
 * L7 information for HTTP flows. It corresponds to Cilium's [accesslog.LogRecordHTTP](https://github.com/cilium/cilium/blob/728c79e427438ab6f8d9375b62fccd6fed4ace3a/pkg/proxy/accesslog/record.go#L206) type.
 *
 * @generated from protobuf message flow.HTTP
 */
export interface HTTP {
    /**
     * @generated from protobuf field: uint32 code = 1;
     */
    code: number;
    /**
     * @generated from protobuf field: string method = 2;
     */
    method: string;
    /**
     * @generated from protobuf field: string url = 3;
     */
    url: string;
    /**
     * @generated from protobuf field: string protocol = 4;
     */
    protocol: string;
    /**
     * @generated from protobuf field: repeated flow.HTTPHeader headers = 5;
     */
    headers: HTTPHeader[];
}
/**
 * L7 information for Kafka flows. It corresponds to Cilium's [accesslog.LogRecordKafka](https://github.com/cilium/cilium/blob/728c79e427438ab6f8d9375b62fccd6fed4ace3a/pkg/proxy/accesslog/record.go#L229) type.
 *
 * @generated from protobuf message flow.Kafka
 */
export interface Kafka {
    /**
     * @generated from protobuf field: int32 error_code = 1;
     */
    errorCode: number;
    /**
     * @generated from protobuf field: int32 api_version = 2;
     */
    apiVersion: number;
    /**
     * @generated from protobuf field: string api_key = 3;
     */
    apiKey: string;
    /**
     * @generated from protobuf field: int32 correlation_id = 4;
     */
    correlationId: number;
    /**
     * @generated from protobuf field: string topic = 5;
     */
    topic: string;
}
/**
 * @generated from protobuf message flow.Service
 */
export interface Service {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: string namespace = 2;
     */
    namespace: string;
}
/**
 * LostEvent is a message which notifies consumers about a loss of events
 * that happened before the events were captured by Hubble.
 *
 * @generated from protobuf message flow.LostEvent
 */
export interface LostEvent {
    /**
     * source is the location where events got lost.
     *
     * @generated from protobuf field: flow.LostEventSource source = 1;
     */
    source: LostEventSource;
    /**
     * num_events_lost is the number of events that haven been lost at source.
     *
     * @generated from protobuf field: uint64 num_events_lost = 2;
     */
    numEventsLost: number;
    /**
     * cpu on which the event was lost if the source of lost events is
     * PERF_EVENT_RING_BUFFER.
     *
     * @generated from protobuf field: google.protobuf.Int32Value cpu = 3;
     */
    cpu?: Int32Value;
}
/**
 * @generated from protobuf message flow.AgentEvent
 */
export interface AgentEvent {
    /**
     * @generated from protobuf field: flow.AgentEventType type = 1;
     */
    type: AgentEventType;
    /**
     * @generated from protobuf oneof: notification
     */
    notification: {
        oneofKind: "unknown";
        /**
         * @generated from protobuf field: flow.AgentEventUnknown unknown = 100;
         */
        unknown: AgentEventUnknown;
    } | {
        oneofKind: "agentStart";
        /**
         * @generated from protobuf field: flow.TimeNotification agent_start = 101;
         */
        agentStart: TimeNotification;
    } | {
        oneofKind: "policyUpdate";
        /**
         * used for POLICY_UPDATED and POLICY_DELETED
         *
         * @generated from protobuf field: flow.PolicyUpdateNotification policy_update = 102;
         */
        policyUpdate: PolicyUpdateNotification;
    } | {
        oneofKind: "endpointRegenerate";
        /**
         * used for ENDPOINT_REGENERATE_SUCCESS and ENDPOINT_REGENERATE_FAILURE
         *
         * @generated from protobuf field: flow.EndpointRegenNotification endpoint_regenerate = 103;
         */
        endpointRegenerate: EndpointRegenNotification;
    } | {
        oneofKind: "endpointUpdate";
        /**
         * used for ENDPOINT_CREATED and ENDPOINT_DELETED
         *
         * @generated from protobuf field: flow.EndpointUpdateNotification endpoint_update = 104;
         */
        endpointUpdate: EndpointUpdateNotification;
    } | {
        oneofKind: "ipcacheUpdate";
        /**
         * used for IPCACHE_UPSERTED and IPCACHE_DELETED
         *
         * @generated from protobuf field: flow.IPCacheNotification ipcache_update = 105;
         */
        ipcacheUpdate: IPCacheNotification;
    } | {
        oneofKind: "serviceUpsert";
        /**
         * @generated from protobuf field: flow.ServiceUpsertNotification service_upsert = 106;
         */
        serviceUpsert: ServiceUpsertNotification;
    } | {
        oneofKind: "serviceDelete";
        /**
         * @generated from protobuf field: flow.ServiceDeleteNotification service_delete = 107;
         */
        serviceDelete: ServiceDeleteNotification;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message flow.AgentEventUnknown
 */
export interface AgentEventUnknown {
    /**
     * @generated from protobuf field: string type = 1;
     */
    type: string;
    /**
     * @generated from protobuf field: string notification = 2;
     */
    notification: string;
}
/**
 * @generated from protobuf message flow.TimeNotification
 */
export interface TimeNotification {
    /**
     * @generated from protobuf field: google.protobuf.Timestamp time = 1;
     */
    time?: Timestamp;
}
/**
 * @generated from protobuf message flow.PolicyUpdateNotification
 */
export interface PolicyUpdateNotification {
    /**
     * @generated from protobuf field: repeated string labels = 1;
     */
    labels: string[];
    /**
     * @generated from protobuf field: uint64 revision = 2;
     */
    revision: number;
    /**
     * @generated from protobuf field: int64 rule_count = 3;
     */
    ruleCount: number;
}
/**
 * @generated from protobuf message flow.EndpointRegenNotification
 */
export interface EndpointRegenNotification {
    /**
     * @generated from protobuf field: uint64 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: repeated string labels = 2;
     */
    labels: string[];
    /**
     * @generated from protobuf field: string error = 3;
     */
    error: string;
}
/**
 * @generated from protobuf message flow.EndpointUpdateNotification
 */
export interface EndpointUpdateNotification {
    /**
     * @generated from protobuf field: uint64 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: repeated string labels = 2;
     */
    labels: string[];
    /**
     * @generated from protobuf field: string error = 3;
     */
    error: string;
    /**
     * @generated from protobuf field: string pod_name = 4;
     */
    podName: string;
    /**
     * @generated from protobuf field: string namespace = 5;
     */
    namespace: string;
}
/**
 * @generated from protobuf message flow.IPCacheNotification
 */
export interface IPCacheNotification {
    /**
     * @generated from protobuf field: string cidr = 1;
     */
    cidr: string;
    /**
     * @generated from protobuf field: uint32 identity = 2;
     */
    identity: number;
    /**
     * @generated from protobuf field: google.protobuf.UInt32Value old_identity = 3;
     */
    oldIdentity?: UInt32Value;
    /**
     * @generated from protobuf field: string host_ip = 4;
     */
    hostIp: string;
    /**
     * @generated from protobuf field: string old_host_ip = 5;
     */
    oldHostIp: string;
    /**
     * @generated from protobuf field: uint32 encrypt_key = 6;
     */
    encryptKey: number;
    /**
     * @generated from protobuf field: string namespace = 7;
     */
    namespace: string;
    /**
     * @generated from protobuf field: string pod_name = 8;
     */
    podName: string;
}
/**
 * @generated from protobuf message flow.ServiceUpsertNotificationAddr
 */
export interface ServiceUpsertNotificationAddr {
    /**
     * @generated from protobuf field: string ip = 1;
     */
    ip: string;
    /**
     * @generated from protobuf field: uint32 port = 2;
     */
    port: number;
}
/**
 * @generated from protobuf message flow.ServiceUpsertNotification
 */
export interface ServiceUpsertNotification {
    /**
     * @generated from protobuf field: uint32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: flow.ServiceUpsertNotificationAddr frontend_address = 2;
     */
    frontendAddress?: ServiceUpsertNotificationAddr;
    /**
     * @generated from protobuf field: repeated flow.ServiceUpsertNotificationAddr backend_addresses = 3;
     */
    backendAddresses: ServiceUpsertNotificationAddr[];
    /**
     * @generated from protobuf field: string type = 4;
     */
    type: string;
    /**
     * @deprecated
     * @generated from protobuf field: string traffic_policy = 5 [deprecated = true];
     */
    trafficPolicy: string;
    /**
     * @generated from protobuf field: string name = 6;
     */
    name: string;
    /**
     * @generated from protobuf field: string namespace = 7;
     */
    namespace: string;
    /**
     * @generated from protobuf field: string ext_traffic_policy = 8;
     */
    extTrafficPolicy: string;
    /**
     * @generated from protobuf field: string int_traffic_policy = 9;
     */
    intTrafficPolicy: string;
}
/**
 * @generated from protobuf message flow.ServiceDeleteNotification
 */
export interface ServiceDeleteNotification {
    /**
     * @generated from protobuf field: uint32 id = 1;
     */
    id: number;
}
/**
 * @generated from protobuf message flow.NetworkInterface
 */
export interface NetworkInterface {
    /**
     * @generated from protobuf field: uint32 index = 1;
     */
    index: number;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
}
/**
 * @generated from protobuf message flow.DebugEvent
 */
export interface DebugEvent {
    /**
     * @generated from protobuf field: flow.DebugEventType type = 1;
     */
    type: DebugEventType;
    /**
     * @generated from protobuf field: flow.Endpoint source = 2;
     */
    source?: Endpoint;
    /**
     * @generated from protobuf field: google.protobuf.UInt32Value hash = 3;
     */
    hash?: UInt32Value;
    /**
     * @generated from protobuf field: google.protobuf.UInt32Value arg1 = 4;
     */
    arg1?: UInt32Value;
    /**
     * @generated from protobuf field: google.protobuf.UInt32Value arg2 = 5;
     */
    arg2?: UInt32Value;
    /**
     * @generated from protobuf field: google.protobuf.UInt32Value arg3 = 6;
     */
    arg3?: UInt32Value;
    /**
     * @generated from protobuf field: string message = 7;
     */
    message: string;
    /**
     * @generated from protobuf field: google.protobuf.Int32Value cpu = 8;
     */
    cpu?: Int32Value;
}
/**
 * @generated from protobuf enum flow.FlowType
 */
export enum FlowType {
    /**
     * @generated from protobuf enum value: UNKNOWN_TYPE = 0;
     */
    UNKNOWN_TYPE = 0,
    /**
     * not sure about the underscore here, but `L34` also reads strange
     *
     * @generated from protobuf enum value: L3_L4 = 1;
     */
    L3_L4 = 1,
    /**
     * @generated from protobuf enum value: L7 = 2;
     */
    L7 = 2,
    /**
     * @generated from protobuf enum value: SOCK = 3;
     */
    SOCK = 3
}
/**
 * These types correspond to definitions in pkg/policy/l4.go.
 *
 * @generated from protobuf enum flow.AuthType
 */
export enum AuthType {
    /**
     * @generated from protobuf enum value: DISABLED = 0;
     */
    DISABLED = 0,
    /**
     * @generated from protobuf enum value: SPIRE = 1;
     */
    SPIRE = 1,
    /**
     * @generated from protobuf enum value: TEST_ALWAYS_FAIL = 2;
     */
    TEST_ALWAYS_FAIL = 2
}
/**
 * @generated from protobuf enum flow.TraceObservationPoint
 */
export enum TraceObservationPoint {
    /**
     * Cilium treats 0 as TO_LXC, but its's something we should work to remove.
     * This is intentionally set as unknown, so proto API can guarantee the
     * observation point is always going to be present on trace events.
     *
     * @generated from protobuf enum value: UNKNOWN_POINT = 0;
     */
    UNKNOWN_POINT = 0,
    /**
     * TO_PROXY indicates network packets are transmitted towards the l7 proxy.
     *
     * @generated from protobuf enum value: TO_PROXY = 1;
     */
    TO_PROXY = 1,
    /**
     * TO_HOST indicates network packets are transmitted towards the host
     * namespace.
     *
     * @generated from protobuf enum value: TO_HOST = 2;
     */
    TO_HOST = 2,
    /**
     * TO_STACK indicates network packets are transmitted towards the Linux
     * kernel network stack on host machine.
     *
     * @generated from protobuf enum value: TO_STACK = 3;
     */
    TO_STACK = 3,
    /**
     * TO_OVERLAY indicates network packets are transmitted towards the tunnel
     * device.
     *
     * @generated from protobuf enum value: TO_OVERLAY = 4;
     */
    TO_OVERLAY = 4,
    /**
     * TO_ENDPOINT indicates network packets are transmitted towards endpoints
     * (containers).
     *
     * @generated from protobuf enum value: TO_ENDPOINT = 101;
     */
    TO_ENDPOINT = 101,
    /**
     * FROM_ENDPOINT indicates network packets were received from endpoints
     * (containers).
     *
     * @generated from protobuf enum value: FROM_ENDPOINT = 5;
     */
    FROM_ENDPOINT = 5,
    /**
     * FROM_PROXY indicates network packets were received from the l7 proxy.
     *
     * @generated from protobuf enum value: FROM_PROXY = 6;
     */
    FROM_PROXY = 6,
    /**
     * FROM_HOST indicates network packets were received from the host
     * namespace.
     *
     * @generated from protobuf enum value: FROM_HOST = 7;
     */
    FROM_HOST = 7,
    /**
     * FROM_STACK indicates network packets were received from the Linux kernel
     * network stack on host machine.
     *
     * @generated from protobuf enum value: FROM_STACK = 8;
     */
    FROM_STACK = 8,
    /**
     * FROM_OVERLAY indicates network packets were received from the tunnel
     * device.
     *
     * @generated from protobuf enum value: FROM_OVERLAY = 9;
     */
    FROM_OVERLAY = 9,
    /**
     * FROM_NETWORK indicates network packets were received from native
     * devices.
     *
     * @generated from protobuf enum value: FROM_NETWORK = 10;
     */
    FROM_NETWORK = 10,
    /**
     * TO_NETWORK indicates network packets are transmitted towards native
     * devices.
     *
     * @generated from protobuf enum value: TO_NETWORK = 11;
     */
    TO_NETWORK = 11
}
/**
 * @generated from protobuf enum flow.TraceReason
 */
export enum TraceReason {
    /**
     * @generated from protobuf enum value: TRACE_REASON_UNKNOWN = 0;
     */
    TRACE_REASON_UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: NEW = 1;
     */
    NEW = 1,
    /**
     * @generated from protobuf enum value: ESTABLISHED = 2;
     */
    ESTABLISHED = 2,
    /**
     * @generated from protobuf enum value: REPLY = 3;
     */
    REPLY = 3,
    /**
     * @generated from protobuf enum value: RELATED = 4;
     */
    RELATED = 4,
    /**
     * @deprecated
     * @generated from protobuf enum value: REOPENED = 5 [deprecated = true];
     */
    REOPENED = 5,
    /**
     * @generated from protobuf enum value: SRV6_ENCAP = 6;
     */
    SRV6_ENCAP = 6,
    /**
     * @generated from protobuf enum value: SRV6_DECAP = 7;
     */
    SRV6_DECAP = 7,
    /**
     * @generated from protobuf enum value: ENCRYPT_OVERLAY = 8;
     */
    ENCRYPT_OVERLAY = 8
}
/**
 * This enum corresponds to Cilium's L7 accesslog [FlowType](https://github.com/cilium/cilium/blob/728c79e427438ab6f8d9375b62fccd6fed4ace3a/pkg/proxy/accesslog/record.go#L26):
 *
 * @generated from protobuf enum flow.L7FlowType
 */
export enum L7FlowType {
    /**
     * @generated from protobuf enum value: UNKNOWN_L7_TYPE = 0;
     */
    UNKNOWN_L7_TYPE = 0,
    /**
     * @generated from protobuf enum value: REQUEST = 1;
     */
    REQUEST = 1,
    /**
     * @generated from protobuf enum value: RESPONSE = 2;
     */
    RESPONSE = 2,
    /**
     * @generated from protobuf enum value: SAMPLE = 3;
     */
    SAMPLE = 3
}
/**
 * @generated from protobuf enum flow.IPVersion
 */
export enum IPVersion {
    /**
     * @generated from protobuf enum value: IP_NOT_USED = 0;
     */
    IP_NOT_USED = 0,
    /**
     * @generated from protobuf enum value: IPv4 = 1;
     */
    IPv4 = 1,
    /**
     * @generated from protobuf enum value: IPv6 = 2;
     */
    IPv6 = 2
}
/**
 * @generated from protobuf enum flow.Verdict
 */
export enum Verdict {
    /**
     * UNKNOWN is used if there is no verdict for this flow event
     *
     * @generated from protobuf enum value: VERDICT_UNKNOWN = 0;
     */
    VERDICT_UNKNOWN = 0,
    /**
     * FORWARDED is used for flow events where the trace point has forwarded
     * this packet or connection to the next processing entity.
     *
     * @generated from protobuf enum value: FORWARDED = 1;
     */
    FORWARDED = 1,
    /**
     * DROPPED is used for flow events where the connection or packet has
     * been dropped (e.g. due to a malformed packet, it being rejected by a
     * network policy etc). The exact drop reason may be found in drop_reason_desc.
     *
     * @generated from protobuf enum value: DROPPED = 2;
     */
    DROPPED = 2,
    /**
     * ERROR is used for flow events where an error occurred during processing
     *
     * @generated from protobuf enum value: ERROR = 3;
     */
    ERROR = 3,
    /**
     * AUDIT is used on policy verdict events in policy audit mode, to
     * denominate flows that would have been dropped by policy if audit mode
     * was turned off
     *
     * @generated from protobuf enum value: AUDIT = 4;
     */
    AUDIT = 4,
    /**
     * REDIRECTED is used for flow events which have been redirected to the proxy
     *
     * @generated from protobuf enum value: REDIRECTED = 5;
     */
    REDIRECTED = 5,
    /**
     * TRACED is used for flow events which have been observed at a trace point,
     * but no particular verdict has been reached yet
     *
     * @generated from protobuf enum value: TRACED = 6;
     */
    TRACED = 6,
    /**
     * TRANSLATED is used for flow events where an address has been translated
     *
     * @generated from protobuf enum value: TRANSLATED = 7;
     */
    TRANSLATED = 7
}
/**
 * These values are shared with pkg/monitor/api/drop.go and bpf/lib/common.h.
 * Note that non-drop reasons (i.e. values less than api.DropMin) are not used
 * here.
 *
 * @generated from protobuf enum flow.DropReason
 */
export enum DropReason {
    /**
     * non-drop reasons
     *
     * @generated from protobuf enum value: DROP_REASON_UNKNOWN = 0;
     */
    DROP_REASON_UNKNOWN = 0,
    /**
     * drop reasons
     *
     * @deprecated
     * @generated from protobuf enum value: INVALID_SOURCE_MAC = 130 [deprecated = true];
     */
    INVALID_SOURCE_MAC = 130,
    /**
     * @deprecated
     * @generated from protobuf enum value: INVALID_DESTINATION_MAC = 131 [deprecated = true];
     */
    INVALID_DESTINATION_MAC = 131,
    /**
     * @generated from protobuf enum value: INVALID_SOURCE_IP = 132;
     */
    INVALID_SOURCE_IP = 132,
    /**
     * @generated from protobuf enum value: POLICY_DENIED = 133;
     */
    POLICY_DENIED = 133,
    /**
     * @generated from protobuf enum value: INVALID_PACKET_DROPPED = 134;
     */
    INVALID_PACKET_DROPPED = 134,
    /**
     * @generated from protobuf enum value: CT_TRUNCATED_OR_INVALID_HEADER = 135;
     */
    CT_TRUNCATED_OR_INVALID_HEADER = 135,
    /**
     * @generated from protobuf enum value: CT_MISSING_TCP_ACK_FLAG = 136;
     */
    CT_MISSING_TCP_ACK_FLAG = 136,
    /**
     * @generated from protobuf enum value: CT_UNKNOWN_L4_PROTOCOL = 137;
     */
    CT_UNKNOWN_L4_PROTOCOL = 137,
    /**
     * @deprecated
     * @generated from protobuf enum value: CT_CANNOT_CREATE_ENTRY_FROM_PACKET = 138 [deprecated = true];
     */
    CT_CANNOT_CREATE_ENTRY_FROM_PACKET = 138,
    /**
     * @generated from protobuf enum value: UNSUPPORTED_L3_PROTOCOL = 139;
     */
    UNSUPPORTED_L3_PROTOCOL = 139,
    /**
     * @generated from protobuf enum value: MISSED_TAIL_CALL = 140;
     */
    MISSED_TAIL_CALL = 140,
    /**
     * @generated from protobuf enum value: ERROR_WRITING_TO_PACKET = 141;
     */
    ERROR_WRITING_TO_PACKET = 141,
    /**
     * @generated from protobuf enum value: UNKNOWN_L4_PROTOCOL = 142;
     */
    UNKNOWN_L4_PROTOCOL = 142,
    /**
     * @generated from protobuf enum value: UNKNOWN_ICMPV4_CODE = 143;
     */
    UNKNOWN_ICMPV4_CODE = 143,
    /**
     * @generated from protobuf enum value: UNKNOWN_ICMPV4_TYPE = 144;
     */
    UNKNOWN_ICMPV4_TYPE = 144,
    /**
     * @generated from protobuf enum value: UNKNOWN_ICMPV6_CODE = 145;
     */
    UNKNOWN_ICMPV6_CODE = 145,
    /**
     * @generated from protobuf enum value: UNKNOWN_ICMPV6_TYPE = 146;
     */
    UNKNOWN_ICMPV6_TYPE = 146,
    /**
     * @generated from protobuf enum value: ERROR_RETRIEVING_TUNNEL_KEY = 147;
     */
    ERROR_RETRIEVING_TUNNEL_KEY = 147,
    /**
     * @deprecated
     * @generated from protobuf enum value: ERROR_RETRIEVING_TUNNEL_OPTIONS = 148 [deprecated = true];
     */
    ERROR_RETRIEVING_TUNNEL_OPTIONS = 148,
    /**
     * @deprecated
     * @generated from protobuf enum value: INVALID_GENEVE_OPTION = 149 [deprecated = true];
     */
    INVALID_GENEVE_OPTION = 149,
    /**
     * @generated from protobuf enum value: UNKNOWN_L3_TARGET_ADDRESS = 150;
     */
    UNKNOWN_L3_TARGET_ADDRESS = 150,
    /**
     * @generated from protobuf enum value: STALE_OR_UNROUTABLE_IP = 151;
     */
    STALE_OR_UNROUTABLE_IP = 151,
    /**
     * @deprecated
     * @generated from protobuf enum value: NO_MATCHING_LOCAL_CONTAINER_FOUND = 152 [deprecated = true];
     */
    NO_MATCHING_LOCAL_CONTAINER_FOUND = 152,
    /**
     * @generated from protobuf enum value: ERROR_WHILE_CORRECTING_L3_CHECKSUM = 153;
     */
    ERROR_WHILE_CORRECTING_L3_CHECKSUM = 153,
    /**
     * @generated from protobuf enum value: ERROR_WHILE_CORRECTING_L4_CHECKSUM = 154;
     */
    ERROR_WHILE_CORRECTING_L4_CHECKSUM = 154,
    /**
     * @generated from protobuf enum value: CT_MAP_INSERTION_FAILED = 155;
     */
    CT_MAP_INSERTION_FAILED = 155,
    /**
     * @generated from protobuf enum value: INVALID_IPV6_EXTENSION_HEADER = 156;
     */
    INVALID_IPV6_EXTENSION_HEADER = 156,
    /**
     * @generated from protobuf enum value: IP_FRAGMENTATION_NOT_SUPPORTED = 157;
     */
    IP_FRAGMENTATION_NOT_SUPPORTED = 157,
    /**
     * @generated from protobuf enum value: SERVICE_BACKEND_NOT_FOUND = 158;
     */
    SERVICE_BACKEND_NOT_FOUND = 158,
    /**
     * @generated from protobuf enum value: NO_TUNNEL_OR_ENCAPSULATION_ENDPOINT = 160;
     */
    NO_TUNNEL_OR_ENCAPSULATION_ENDPOINT = 160,
    /**
     * @generated from protobuf enum value: FAILED_TO_INSERT_INTO_PROXYMAP = 161;
     */
    FAILED_TO_INSERT_INTO_PROXYMAP = 161,
    /**
     * @generated from protobuf enum value: REACHED_EDT_RATE_LIMITING_DROP_HORIZON = 162;
     */
    REACHED_EDT_RATE_LIMITING_DROP_HORIZON = 162,
    /**
     * @generated from protobuf enum value: UNKNOWN_CONNECTION_TRACKING_STATE = 163;
     */
    UNKNOWN_CONNECTION_TRACKING_STATE = 163,
    /**
     * @generated from protobuf enum value: LOCAL_HOST_IS_UNREACHABLE = 164;
     */
    LOCAL_HOST_IS_UNREACHABLE = 164,
    /**
     * @generated from protobuf enum value: NO_CONFIGURATION_AVAILABLE_TO_PERFORM_POLICY_DECISION = 165;
     */
    NO_CONFIGURATION_AVAILABLE_TO_PERFORM_POLICY_DECISION = 165,
    /**
     * @generated from protobuf enum value: UNSUPPORTED_L2_PROTOCOL = 166;
     */
    UNSUPPORTED_L2_PROTOCOL = 166,
    /**
     * @generated from protobuf enum value: NO_MAPPING_FOR_NAT_MASQUERADE = 167;
     */
    NO_MAPPING_FOR_NAT_MASQUERADE = 167,
    /**
     * @generated from protobuf enum value: UNSUPPORTED_PROTOCOL_FOR_NAT_MASQUERADE = 168;
     */
    UNSUPPORTED_PROTOCOL_FOR_NAT_MASQUERADE = 168,
    /**
     * @generated from protobuf enum value: FIB_LOOKUP_FAILED = 169;
     */
    FIB_LOOKUP_FAILED = 169,
    /**
     * @generated from protobuf enum value: ENCAPSULATION_TRAFFIC_IS_PROHIBITED = 170;
     */
    ENCAPSULATION_TRAFFIC_IS_PROHIBITED = 170,
    /**
     * @generated from protobuf enum value: INVALID_IDENTITY = 171;
     */
    INVALID_IDENTITY = 171,
    /**
     * @generated from protobuf enum value: UNKNOWN_SENDER = 172;
     */
    UNKNOWN_SENDER = 172,
    /**
     * @generated from protobuf enum value: NAT_NOT_NEEDED = 173;
     */
    NAT_NOT_NEEDED = 173,
    /**
     * @generated from protobuf enum value: IS_A_CLUSTERIP = 174;
     */
    IS_A_CLUSTERIP = 174,
    /**
     * @generated from protobuf enum value: FIRST_LOGICAL_DATAGRAM_FRAGMENT_NOT_FOUND = 175;
     */
    FIRST_LOGICAL_DATAGRAM_FRAGMENT_NOT_FOUND = 175,
    /**
     * @generated from protobuf enum value: FORBIDDEN_ICMPV6_MESSAGE = 176;
     */
    FORBIDDEN_ICMPV6_MESSAGE = 176,
    /**
     * @generated from protobuf enum value: DENIED_BY_LB_SRC_RANGE_CHECK = 177;
     */
    DENIED_BY_LB_SRC_RANGE_CHECK = 177,
    /**
     * @generated from protobuf enum value: SOCKET_LOOKUP_FAILED = 178;
     */
    SOCKET_LOOKUP_FAILED = 178,
    /**
     * @generated from protobuf enum value: SOCKET_ASSIGN_FAILED = 179;
     */
    SOCKET_ASSIGN_FAILED = 179,
    /**
     * @generated from protobuf enum value: PROXY_REDIRECTION_NOT_SUPPORTED_FOR_PROTOCOL = 180;
     */
    PROXY_REDIRECTION_NOT_SUPPORTED_FOR_PROTOCOL = 180,
    /**
     * @generated from protobuf enum value: POLICY_DENY = 181;
     */
    POLICY_DENY = 181,
    /**
     * @generated from protobuf enum value: VLAN_FILTERED = 182;
     */
    VLAN_FILTERED = 182,
    /**
     * @generated from protobuf enum value: INVALID_VNI = 183;
     */
    INVALID_VNI = 183,
    /**
     * @generated from protobuf enum value: INVALID_TC_BUFFER = 184;
     */
    INVALID_TC_BUFFER = 184,
    /**
     * @generated from protobuf enum value: NO_SID = 185;
     */
    NO_SID = 185,
    /**
     * @deprecated
     * @generated from protobuf enum value: MISSING_SRV6_STATE = 186 [deprecated = true];
     */
    MISSING_SRV6_STATE = 186,
    /**
     * @generated from protobuf enum value: NAT46 = 187;
     */
    NAT46 = 187,
    /**
     * @generated from protobuf enum value: NAT64 = 188;
     */
    NAT64 = 188,
    /**
     * @generated from protobuf enum value: AUTH_REQUIRED = 189;
     */
    AUTH_REQUIRED = 189,
    /**
     * @generated from protobuf enum value: CT_NO_MAP_FOUND = 190;
     */
    CT_NO_MAP_FOUND = 190,
    /**
     * @generated from protobuf enum value: SNAT_NO_MAP_FOUND = 191;
     */
    SNAT_NO_MAP_FOUND = 191,
    /**
     * @generated from protobuf enum value: INVALID_CLUSTER_ID = 192;
     */
    INVALID_CLUSTER_ID = 192,
    /**
     * @generated from protobuf enum value: UNSUPPORTED_PROTOCOL_FOR_DSR_ENCAP = 193;
     */
    UNSUPPORTED_PROTOCOL_FOR_DSR_ENCAP = 193,
    /**
     * @generated from protobuf enum value: NO_EGRESS_GATEWAY = 194;
     */
    NO_EGRESS_GATEWAY = 194,
    /**
     * @generated from protobuf enum value: UNENCRYPTED_TRAFFIC = 195;
     */
    UNENCRYPTED_TRAFFIC = 195,
    /**
     * @generated from protobuf enum value: TTL_EXCEEDED = 196;
     */
    TTL_EXCEEDED = 196,
    /**
     * @generated from protobuf enum value: NO_NODE_ID = 197;
     */
    NO_NODE_ID = 197,
    /**
     * @generated from protobuf enum value: DROP_RATE_LIMITED = 198;
     */
    DROP_RATE_LIMITED = 198,
    /**
     * @generated from protobuf enum value: IGMP_HANDLED = 199;
     */
    IGMP_HANDLED = 199,
    /**
     * @generated from protobuf enum value: IGMP_SUBSCRIBED = 200;
     */
    IGMP_SUBSCRIBED = 200,
    /**
     * @generated from protobuf enum value: MULTICAST_HANDLED = 201;
     */
    MULTICAST_HANDLED = 201,
    /**
     * A BPF program wants to tail call into bpf_host, but the host datapath
     * hasn't been loaded yet.
     *
     * @generated from protobuf enum value: DROP_HOST_NOT_READY = 202;
     */
    DROP_HOST_NOT_READY = 202,
    /**
     * A BPF program wants to tail call some endpoint's policy program in
     * cilium_call_policy, but the program is not available.
     *
     * @generated from protobuf enum value: DROP_EP_NOT_READY = 203;
     */
    DROP_EP_NOT_READY = 203,
    /**
     * An Egress Gateway node matched a packet against an Egress Gateway policy
     * that didn't select a valid Egress IP.
     *
     * @generated from protobuf enum value: DROP_NO_EGRESS_IP = 204;
     */
    DROP_NO_EGRESS_IP = 204
}
/**
 * @generated from protobuf enum flow.TrafficDirection
 */
export enum TrafficDirection {
    /**
     * @generated from protobuf enum value: TRAFFIC_DIRECTION_UNKNOWN = 0;
     */
    TRAFFIC_DIRECTION_UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: INGRESS = 1;
     */
    INGRESS = 1,
    /**
     * @generated from protobuf enum value: EGRESS = 2;
     */
    EGRESS = 2
}
/**
 * These values are shared with pkg/monitor/api/datapath_debug.go and bpf/lib/dbg.h.
 *
 * @generated from protobuf enum flow.DebugCapturePoint
 */
export enum DebugCapturePoint {
    /**
     * @generated from protobuf enum value: DBG_CAPTURE_POINT_UNKNOWN = 0;
     */
    DBG_CAPTURE_POINT_UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: DBG_CAPTURE_DELIVERY = 4;
     */
    DBG_CAPTURE_DELIVERY = 4,
    /**
     * @generated from protobuf enum value: DBG_CAPTURE_FROM_LB = 5;
     */
    DBG_CAPTURE_FROM_LB = 5,
    /**
     * @generated from protobuf enum value: DBG_CAPTURE_AFTER_V46 = 6;
     */
    DBG_CAPTURE_AFTER_V46 = 6,
    /**
     * @generated from protobuf enum value: DBG_CAPTURE_AFTER_V64 = 7;
     */
    DBG_CAPTURE_AFTER_V64 = 7,
    /**
     * @generated from protobuf enum value: DBG_CAPTURE_PROXY_PRE = 8;
     */
    DBG_CAPTURE_PROXY_PRE = 8,
    /**
     * @generated from protobuf enum value: DBG_CAPTURE_PROXY_POST = 9;
     */
    DBG_CAPTURE_PROXY_POST = 9,
    /**
     * @generated from protobuf enum value: DBG_CAPTURE_SNAT_PRE = 10;
     */
    DBG_CAPTURE_SNAT_PRE = 10,
    /**
     * @generated from protobuf enum value: DBG_CAPTURE_SNAT_POST = 11;
     */
    DBG_CAPTURE_SNAT_POST = 11
}
/**
 * EventType are constants are based on the ones from <linux/perf_event.h>.
 *
 * @generated from protobuf enum flow.EventType
 */
export enum EventType {
    /**
     * @generated from protobuf enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * EventSample is equivalent to PERF_RECORD_SAMPLE.
     *
     * @generated from protobuf enum value: EventSample = 9;
     */
    EventSample = 9,
    /**
     * RecordLost is equivalent to PERF_RECORD_LOST.
     *
     * @generated from protobuf enum value: RecordLost = 2;
     */
    RecordLost = 2
}
/**
 * @generated from protobuf enum flow.LostEventSource
 */
export enum LostEventSource {
    /**
     * @generated from protobuf enum value: UNKNOWN_LOST_EVENT_SOURCE = 0;
     */
    UNKNOWN_LOST_EVENT_SOURCE = 0,
    /**
     * PERF_EVENT_RING_BUFFER indicates that events were dropped in the BPF
     * perf event ring buffer, indicating that userspace agent did not keep up
     * with the events produced by the datapath.
     *
     * @generated from protobuf enum value: PERF_EVENT_RING_BUFFER = 1;
     */
    PERF_EVENT_RING_BUFFER = 1,
    /**
     * OBSERVER_EVENTS_QUEUE indicates that events were dropped because the
     * Hubble events queue was full, indicating that the Hubble observer did
     * not keep up.
     *
     * @generated from protobuf enum value: OBSERVER_EVENTS_QUEUE = 2;
     */
    OBSERVER_EVENTS_QUEUE = 2,
    /**
     * HUBBLE_RING_BUFFER indicates that the event was dropped because it could
     * not be read from Hubble's ring buffer in time before being overwritten.
     *
     * @generated from protobuf enum value: HUBBLE_RING_BUFFER = 3;
     */
    HUBBLE_RING_BUFFER = 3
}
/**
 * AgentEventType is the type of agent event. These values are shared with type
 * AgentNotification in pkg/monitor/api/types.go.
 *
 * @generated from protobuf enum flow.AgentEventType
 */
export enum AgentEventType {
    /**
     * @generated from protobuf enum value: AGENT_EVENT_UNKNOWN = 0;
     */
    AGENT_EVENT_UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: AGENT_STARTED = 2;
     */
    AGENT_STARTED = 2,
    /**
     * @generated from protobuf enum value: POLICY_UPDATED = 3;
     */
    POLICY_UPDATED = 3,
    /**
     * @generated from protobuf enum value: POLICY_DELETED = 4;
     */
    POLICY_DELETED = 4,
    /**
     * @generated from protobuf enum value: ENDPOINT_REGENERATE_SUCCESS = 5;
     */
    ENDPOINT_REGENERATE_SUCCESS = 5,
    /**
     * @generated from protobuf enum value: ENDPOINT_REGENERATE_FAILURE = 6;
     */
    ENDPOINT_REGENERATE_FAILURE = 6,
    /**
     * @generated from protobuf enum value: ENDPOINT_CREATED = 7;
     */
    ENDPOINT_CREATED = 7,
    /**
     * @generated from protobuf enum value: ENDPOINT_DELETED = 8;
     */
    ENDPOINT_DELETED = 8,
    /**
     * @generated from protobuf enum value: IPCACHE_UPSERTED = 9;
     */
    IPCACHE_UPSERTED = 9,
    /**
     * @generated from protobuf enum value: IPCACHE_DELETED = 10;
     */
    IPCACHE_DELETED = 10,
    /**
     * @generated from protobuf enum value: SERVICE_UPSERTED = 11;
     */
    SERVICE_UPSERTED = 11,
    /**
     * @generated from protobuf enum value: SERVICE_DELETED = 12;
     */
    SERVICE_DELETED = 12
}
/**
 * This mirrors enum xlate_point in bpf/lib/trace_sock.h
 *
 * @generated from protobuf enum flow.SocketTranslationPoint
 */
export enum SocketTranslationPoint {
    /**
     * @generated from protobuf enum value: SOCK_XLATE_POINT_UNKNOWN = 0;
     */
    SOCK_XLATE_POINT_UNKNOWN = 0,
    /**
     * Pre service translation
     *
     * @generated from protobuf enum value: SOCK_XLATE_POINT_PRE_DIRECTION_FWD = 1;
     */
    SOCK_XLATE_POINT_PRE_DIRECTION_FWD = 1,
    /**
     * Post service translation
     *
     * @generated from protobuf enum value: SOCK_XLATE_POINT_POST_DIRECTION_FWD = 2;
     */
    SOCK_XLATE_POINT_POST_DIRECTION_FWD = 2,
    /**
     * Pre reverse service translation
     *
     * @generated from protobuf enum value: SOCK_XLATE_POINT_PRE_DIRECTION_REV = 3;
     */
    SOCK_XLATE_POINT_PRE_DIRECTION_REV = 3,
    /**
     * Post reverse service translation
     *
     * @generated from protobuf enum value: SOCK_XLATE_POINT_POST_DIRECTION_REV = 4;
     */
    SOCK_XLATE_POINT_POST_DIRECTION_REV = 4
}
/**
 * These values are shared with pkg/monitor/api/datapath_debug.go and bpf/lib/dbg.h.
 *
 * @generated from protobuf enum flow.DebugEventType
 */
export enum DebugEventType {
    /**
     * @generated from protobuf enum value: DBG_EVENT_UNKNOWN = 0;
     */
    DBG_EVENT_UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: DBG_GENERIC = 1;
     */
    DBG_GENERIC = 1,
    /**
     * @generated from protobuf enum value: DBG_LOCAL_DELIVERY = 2;
     */
    DBG_LOCAL_DELIVERY = 2,
    /**
     * @generated from protobuf enum value: DBG_ENCAP = 3;
     */
    DBG_ENCAP = 3,
    /**
     * @generated from protobuf enum value: DBG_LXC_FOUND = 4;
     */
    DBG_LXC_FOUND = 4,
    /**
     * @generated from protobuf enum value: DBG_POLICY_DENIED = 5;
     */
    DBG_POLICY_DENIED = 5,
    /**
     * @generated from protobuf enum value: DBG_CT_LOOKUP = 6;
     */
    DBG_CT_LOOKUP = 6,
    /**
     * @generated from protobuf enum value: DBG_CT_LOOKUP_REV = 7;
     */
    DBG_CT_LOOKUP_REV = 7,
    /**
     * @generated from protobuf enum value: DBG_CT_MATCH = 8;
     */
    DBG_CT_MATCH = 8,
    /**
     * @generated from protobuf enum value: DBG_CT_CREATED = 9;
     */
    DBG_CT_CREATED = 9,
    /**
     * @generated from protobuf enum value: DBG_CT_CREATED2 = 10;
     */
    DBG_CT_CREATED2 = 10,
    /**
     * @generated from protobuf enum value: DBG_ICMP6_HANDLE = 11;
     */
    DBG_ICMP6_HANDLE = 11,
    /**
     * @generated from protobuf enum value: DBG_ICMP6_REQUEST = 12;
     */
    DBG_ICMP6_REQUEST = 12,
    /**
     * @generated from protobuf enum value: DBG_ICMP6_NS = 13;
     */
    DBG_ICMP6_NS = 13,
    /**
     * @generated from protobuf enum value: DBG_ICMP6_TIME_EXCEEDED = 14;
     */
    DBG_ICMP6_TIME_EXCEEDED = 14,
    /**
     * @generated from protobuf enum value: DBG_CT_VERDICT = 15;
     */
    DBG_CT_VERDICT = 15,
    /**
     * @generated from protobuf enum value: DBG_DECAP = 16;
     */
    DBG_DECAP = 16,
    /**
     * @generated from protobuf enum value: DBG_PORT_MAP = 17;
     */
    DBG_PORT_MAP = 17,
    /**
     * @generated from protobuf enum value: DBG_ERROR_RET = 18;
     */
    DBG_ERROR_RET = 18,
    /**
     * @generated from protobuf enum value: DBG_TO_HOST = 19;
     */
    DBG_TO_HOST = 19,
    /**
     * @generated from protobuf enum value: DBG_TO_STACK = 20;
     */
    DBG_TO_STACK = 20,
    /**
     * @generated from protobuf enum value: DBG_PKT_HASH = 21;
     */
    DBG_PKT_HASH = 21,
    /**
     * @generated from protobuf enum value: DBG_LB6_LOOKUP_FRONTEND = 22;
     */
    DBG_LB6_LOOKUP_FRONTEND = 22,
    /**
     * @generated from protobuf enum value: DBG_LB6_LOOKUP_FRONTEND_FAIL = 23;
     */
    DBG_LB6_LOOKUP_FRONTEND_FAIL = 23,
    /**
     * @generated from protobuf enum value: DBG_LB6_LOOKUP_BACKEND_SLOT = 24;
     */
    DBG_LB6_LOOKUP_BACKEND_SLOT = 24,
    /**
     * @generated from protobuf enum value: DBG_LB6_LOOKUP_BACKEND_SLOT_SUCCESS = 25;
     */
    DBG_LB6_LOOKUP_BACKEND_SLOT_SUCCESS = 25,
    /**
     * @generated from protobuf enum value: DBG_LB6_LOOKUP_BACKEND_SLOT_V2_FAIL = 26;
     */
    DBG_LB6_LOOKUP_BACKEND_SLOT_V2_FAIL = 26,
    /**
     * @generated from protobuf enum value: DBG_LB6_LOOKUP_BACKEND_FAIL = 27;
     */
    DBG_LB6_LOOKUP_BACKEND_FAIL = 27,
    /**
     * @generated from protobuf enum value: DBG_LB6_REVERSE_NAT_LOOKUP = 28;
     */
    DBG_LB6_REVERSE_NAT_LOOKUP = 28,
    /**
     * @generated from protobuf enum value: DBG_LB6_REVERSE_NAT = 29;
     */
    DBG_LB6_REVERSE_NAT = 29,
    /**
     * @generated from protobuf enum value: DBG_LB4_LOOKUP_FRONTEND = 30;
     */
    DBG_LB4_LOOKUP_FRONTEND = 30,
    /**
     * @generated from protobuf enum value: DBG_LB4_LOOKUP_FRONTEND_FAIL = 31;
     */
    DBG_LB4_LOOKUP_FRONTEND_FAIL = 31,
    /**
     * @generated from protobuf enum value: DBG_LB4_LOOKUP_BACKEND_SLOT = 32;
     */
    DBG_LB4_LOOKUP_BACKEND_SLOT = 32,
    /**
     * @generated from protobuf enum value: DBG_LB4_LOOKUP_BACKEND_SLOT_SUCCESS = 33;
     */
    DBG_LB4_LOOKUP_BACKEND_SLOT_SUCCESS = 33,
    /**
     * @generated from protobuf enum value: DBG_LB4_LOOKUP_BACKEND_SLOT_V2_FAIL = 34;
     */
    DBG_LB4_LOOKUP_BACKEND_SLOT_V2_FAIL = 34,
    /**
     * @generated from protobuf enum value: DBG_LB4_LOOKUP_BACKEND_FAIL = 35;
     */
    DBG_LB4_LOOKUP_BACKEND_FAIL = 35,
    /**
     * @generated from protobuf enum value: DBG_LB4_REVERSE_NAT_LOOKUP = 36;
     */
    DBG_LB4_REVERSE_NAT_LOOKUP = 36,
    /**
     * @generated from protobuf enum value: DBG_LB4_REVERSE_NAT = 37;
     */
    DBG_LB4_REVERSE_NAT = 37,
    /**
     * @generated from protobuf enum value: DBG_LB4_LOOPBACK_SNAT = 38;
     */
    DBG_LB4_LOOPBACK_SNAT = 38,
    /**
     * @generated from protobuf enum value: DBG_LB4_LOOPBACK_SNAT_REV = 39;
     */
    DBG_LB4_LOOPBACK_SNAT_REV = 39,
    /**
     * @generated from protobuf enum value: DBG_CT_LOOKUP4 = 40;
     */
    DBG_CT_LOOKUP4 = 40,
    /**
     * @generated from protobuf enum value: DBG_RR_BACKEND_SLOT_SEL = 41;
     */
    DBG_RR_BACKEND_SLOT_SEL = 41,
    /**
     * @generated from protobuf enum value: DBG_REV_PROXY_LOOKUP = 42;
     */
    DBG_REV_PROXY_LOOKUP = 42,
    /**
     * @generated from protobuf enum value: DBG_REV_PROXY_FOUND = 43;
     */
    DBG_REV_PROXY_FOUND = 43,
    /**
     * @generated from protobuf enum value: DBG_REV_PROXY_UPDATE = 44;
     */
    DBG_REV_PROXY_UPDATE = 44,
    /**
     * @generated from protobuf enum value: DBG_L4_POLICY = 45;
     */
    DBG_L4_POLICY = 45,
    /**
     * @generated from protobuf enum value: DBG_NETDEV_IN_CLUSTER = 46;
     */
    DBG_NETDEV_IN_CLUSTER = 46,
    /**
     * @generated from protobuf enum value: DBG_NETDEV_ENCAP4 = 47;
     */
    DBG_NETDEV_ENCAP4 = 47,
    /**
     * @generated from protobuf enum value: DBG_CT_LOOKUP4_1 = 48;
     */
    DBG_CT_LOOKUP4_1 = 48,
    /**
     * @generated from protobuf enum value: DBG_CT_LOOKUP4_2 = 49;
     */
    DBG_CT_LOOKUP4_2 = 49,
    /**
     * @generated from protobuf enum value: DBG_CT_CREATED4 = 50;
     */
    DBG_CT_CREATED4 = 50,
    /**
     * @generated from protobuf enum value: DBG_CT_LOOKUP6_1 = 51;
     */
    DBG_CT_LOOKUP6_1 = 51,
    /**
     * @generated from protobuf enum value: DBG_CT_LOOKUP6_2 = 52;
     */
    DBG_CT_LOOKUP6_2 = 52,
    /**
     * @generated from protobuf enum value: DBG_CT_CREATED6 = 53;
     */
    DBG_CT_CREATED6 = 53,
    /**
     * @generated from protobuf enum value: DBG_SKIP_PROXY = 54;
     */
    DBG_SKIP_PROXY = 54,
    /**
     * @generated from protobuf enum value: DBG_L4_CREATE = 55;
     */
    DBG_L4_CREATE = 55,
    /**
     * @generated from protobuf enum value: DBG_IP_ID_MAP_FAILED4 = 56;
     */
    DBG_IP_ID_MAP_FAILED4 = 56,
    /**
     * @generated from protobuf enum value: DBG_IP_ID_MAP_FAILED6 = 57;
     */
    DBG_IP_ID_MAP_FAILED6 = 57,
    /**
     * @generated from protobuf enum value: DBG_IP_ID_MAP_SUCCEED4 = 58;
     */
    DBG_IP_ID_MAP_SUCCEED4 = 58,
    /**
     * @generated from protobuf enum value: DBG_IP_ID_MAP_SUCCEED6 = 59;
     */
    DBG_IP_ID_MAP_SUCCEED6 = 59,
    /**
     * @generated from protobuf enum value: DBG_LB_STALE_CT = 60;
     */
    DBG_LB_STALE_CT = 60,
    /**
     * @generated from protobuf enum value: DBG_INHERIT_IDENTITY = 61;
     */
    DBG_INHERIT_IDENTITY = 61,
    /**
     * @generated from protobuf enum value: DBG_SK_LOOKUP4 = 62;
     */
    DBG_SK_LOOKUP4 = 62,
    /**
     * @generated from protobuf enum value: DBG_SK_LOOKUP6 = 63;
     */
    DBG_SK_LOOKUP6 = 63,
    /**
     * @generated from protobuf enum value: DBG_SK_ASSIGN = 64;
     */
    DBG_SK_ASSIGN = 64,
    /**
     * @generated from protobuf enum value: DBG_L7_LB = 65;
     */
    DBG_L7_LB = 65,
    /**
     * @generated from protobuf enum value: DBG_SKIP_POLICY = 66;
     */
    DBG_SKIP_POLICY = 66
}
// @generated message type with reflection information, may provide speed optimized methods
class Flow$Type extends MessageType<Flow> {
    constructor() {
        super("flow.Flow", [
            { no: 1, name: "time", kind: "message", T: () => Timestamp },
            { no: 34, name: "uuid", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "verdict", kind: "enum", T: () => ["flow.Verdict", Verdict] },
            { no: 3, name: "drop_reason", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 35, name: "auth_type", kind: "enum", T: () => ["flow.AuthType", AuthType] },
            { no: 4, name: "ethernet", kind: "message", T: () => Ethernet },
            { no: 5, name: "IP", kind: "message", jsonName: "IP", T: () => IP },
            { no: 6, name: "l4", kind: "message", T: () => Layer4 },
            { no: 8, name: "source", kind: "message", T: () => Endpoint },
            { no: 9, name: "destination", kind: "message", T: () => Endpoint },
            { no: 10, name: "Type", kind: "enum", jsonName: "Type", T: () => ["flow.FlowType", FlowType] },
            { no: 11, name: "node_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 37, name: "node_labels", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "source_names", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "destination_names", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "l7", kind: "message", T: () => Layer7 },
            { no: 16, name: "reply", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 19, name: "event_type", kind: "message", T: () => CiliumEventType },
            { no: 20, name: "source_service", kind: "message", T: () => Service },
            { no: 21, name: "destination_service", kind: "message", T: () => Service },
            { no: 22, name: "traffic_direction", kind: "enum", T: () => ["flow.TrafficDirection", TrafficDirection] },
            { no: 23, name: "policy_match_type", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 24, name: "trace_observation_point", kind: "enum", T: () => ["flow.TraceObservationPoint", TraceObservationPoint] },
            { no: 36, name: "trace_reason", kind: "enum", T: () => ["flow.TraceReason", TraceReason] },
            { no: 38, name: "file", kind: "message", T: () => FileInfo },
            { no: 25, name: "drop_reason_desc", kind: "enum", T: () => ["flow.DropReason", DropReason] },
            { no: 26, name: "is_reply", kind: "message", T: () => BoolValue },
            { no: 27, name: "debug_capture_point", kind: "enum", T: () => ["flow.DebugCapturePoint", DebugCapturePoint] },
            { no: 28, name: "interface", kind: "message", T: () => NetworkInterface },
            { no: 29, name: "proxy_port", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 30, name: "trace_context", kind: "message", T: () => TraceContext },
            { no: 31, name: "sock_xlate_point", kind: "enum", T: () => ["flow.SocketTranslationPoint", SocketTranslationPoint] },
            { no: 32, name: "socket_cookie", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 33, name: "cgroup_id", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 100000, name: "Summary", kind: "scalar", jsonName: "Summary", T: 9 /*ScalarType.STRING*/ },
            { no: 150000, name: "extensions", kind: "message", T: () => Any },
            { no: 21001, name: "egress_allowed_by", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Policy },
            { no: 21002, name: "ingress_allowed_by", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Policy },
            { no: 21004, name: "egress_denied_by", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Policy },
            { no: 21005, name: "ingress_denied_by", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Policy }
        ]);
    }
    create(value?: PartialMessage<Flow>): Flow {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uuid = "";
        message.verdict = 0;
        message.dropReason = 0;
        message.authType = 0;
        message.type = 0;
        message.nodeName = "";
        message.nodeLabels = [];
        message.sourceNames = [];
        message.destinationNames = [];
        message.reply = false;
        message.trafficDirection = 0;
        message.policyMatchType = 0;
        message.traceObservationPoint = 0;
        message.traceReason = 0;
        message.dropReasonDesc = 0;
        message.debugCapturePoint = 0;
        message.proxyPort = 0;
        message.sockXlatePoint = 0;
        message.socketCookie = 0;
        message.cgroupId = 0;
        message.summary = "";
        message.egressAllowedBy = [];
        message.ingressAllowedBy = [];
        message.egressDeniedBy = [];
        message.ingressDeniedBy = [];
        if (value !== undefined)
            reflectionMergePartial<Flow>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Flow): Flow {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Timestamp time */ 1:
                    message.time = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.time);
                    break;
                case /* string uuid */ 34:
                    message.uuid = reader.string();
                    break;
                case /* flow.Verdict verdict */ 2:
                    message.verdict = reader.int32();
                    break;
                case /* uint32 drop_reason = 3 [deprecated = true];*/ 3:
                    message.dropReason = reader.uint32();
                    break;
                case /* flow.AuthType auth_type */ 35:
                    message.authType = reader.int32();
                    break;
                case /* flow.Ethernet ethernet */ 4:
                    message.ethernet = Ethernet.internalBinaryRead(reader, reader.uint32(), options, message.ethernet);
                    break;
                case /* flow.IP IP = 5 [json_name = "IP"];*/ 5:
                    message.iP = IP.internalBinaryRead(reader, reader.uint32(), options, message.iP);
                    break;
                case /* flow.Layer4 l4 */ 6:
                    message.l4 = Layer4.internalBinaryRead(reader, reader.uint32(), options, message.l4);
                    break;
                case /* flow.Endpoint source */ 8:
                    message.source = Endpoint.internalBinaryRead(reader, reader.uint32(), options, message.source);
                    break;
                case /* flow.Endpoint destination */ 9:
                    message.destination = Endpoint.internalBinaryRead(reader, reader.uint32(), options, message.destination);
                    break;
                case /* flow.FlowType Type = 10 [json_name = "Type"];*/ 10:
                    message.type = reader.int32();
                    break;
                case /* string node_name */ 11:
                    message.nodeName = reader.string();
                    break;
                case /* repeated string node_labels */ 37:
                    message.nodeLabels.push(reader.string());
                    break;
                case /* repeated string source_names */ 13:
                    message.sourceNames.push(reader.string());
                    break;
                case /* repeated string destination_names */ 14:
                    message.destinationNames.push(reader.string());
                    break;
                case /* flow.Layer7 l7 */ 15:
                    message.l7 = Layer7.internalBinaryRead(reader, reader.uint32(), options, message.l7);
                    break;
                case /* bool reply = 16 [deprecated = true];*/ 16:
                    message.reply = reader.bool();
                    break;
                case /* flow.CiliumEventType event_type */ 19:
                    message.eventType = CiliumEventType.internalBinaryRead(reader, reader.uint32(), options, message.eventType);
                    break;
                case /* flow.Service source_service */ 20:
                    message.sourceService = Service.internalBinaryRead(reader, reader.uint32(), options, message.sourceService);
                    break;
                case /* flow.Service destination_service */ 21:
                    message.destinationService = Service.internalBinaryRead(reader, reader.uint32(), options, message.destinationService);
                    break;
                case /* flow.TrafficDirection traffic_direction */ 22:
                    message.trafficDirection = reader.int32();
                    break;
                case /* uint32 policy_match_type */ 23:
                    message.policyMatchType = reader.uint32();
                    break;
                case /* flow.TraceObservationPoint trace_observation_point */ 24:
                    message.traceObservationPoint = reader.int32();
                    break;
                case /* flow.TraceReason trace_reason */ 36:
                    message.traceReason = reader.int32();
                    break;
                case /* flow.FileInfo file */ 38:
                    message.file = FileInfo.internalBinaryRead(reader, reader.uint32(), options, message.file);
                    break;
                case /* flow.DropReason drop_reason_desc */ 25:
                    message.dropReasonDesc = reader.int32();
                    break;
                case /* google.protobuf.BoolValue is_reply */ 26:
                    message.isReply = BoolValue.internalBinaryRead(reader, reader.uint32(), options, message.isReply);
                    break;
                case /* flow.DebugCapturePoint debug_capture_point */ 27:
                    message.debugCapturePoint = reader.int32();
                    break;
                case /* flow.NetworkInterface interface */ 28:
                    message.interface = NetworkInterface.internalBinaryRead(reader, reader.uint32(), options, message.interface);
                    break;
                case /* uint32 proxy_port */ 29:
                    message.proxyPort = reader.uint32();
                    break;
                case /* flow.TraceContext trace_context */ 30:
                    message.traceContext = TraceContext.internalBinaryRead(reader, reader.uint32(), options, message.traceContext);
                    break;
                case /* flow.SocketTranslationPoint sock_xlate_point */ 31:
                    message.sockXlatePoint = reader.int32();
                    break;
                case /* uint64 socket_cookie */ 32:
                    message.socketCookie = reader.uint64().toNumber();
                    break;
                case /* uint64 cgroup_id */ 33:
                    message.cgroupId = reader.uint64().toNumber();
                    break;
                case /* string Summary = 100000 [deprecated = true, json_name = "Summary"];*/ 100000:
                    message.summary = reader.string();
                    break;
                case /* google.protobuf.Any extensions */ 150000:
                    message.extensions = Any.internalBinaryRead(reader, reader.uint32(), options, message.extensions);
                    break;
                case /* repeated flow.Policy egress_allowed_by */ 21001:
                    message.egressAllowedBy.push(Policy.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated flow.Policy ingress_allowed_by */ 21002:
                    message.ingressAllowedBy.push(Policy.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated flow.Policy egress_denied_by */ 21004:
                    message.egressDeniedBy.push(Policy.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated flow.Policy ingress_denied_by */ 21005:
                    message.ingressDeniedBy.push(Policy.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Flow, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Timestamp time = 1; */
        if (message.time)
            Timestamp.internalBinaryWrite(message.time, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string uuid = 34; */
        if (message.uuid !== "")
            writer.tag(34, WireType.LengthDelimited).string(message.uuid);
        /* flow.Verdict verdict = 2; */
        if (message.verdict !== 0)
            writer.tag(2, WireType.Varint).int32(message.verdict);
        /* uint32 drop_reason = 3 [deprecated = true]; */
        if (message.dropReason !== 0)
            writer.tag(3, WireType.Varint).uint32(message.dropReason);
        /* flow.AuthType auth_type = 35; */
        if (message.authType !== 0)
            writer.tag(35, WireType.Varint).int32(message.authType);
        /* flow.Ethernet ethernet = 4; */
        if (message.ethernet)
            Ethernet.internalBinaryWrite(message.ethernet, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* flow.IP IP = 5 [json_name = "IP"]; */
        if (message.iP)
            IP.internalBinaryWrite(message.iP, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* flow.Layer4 l4 = 6; */
        if (message.l4)
            Layer4.internalBinaryWrite(message.l4, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* flow.Endpoint source = 8; */
        if (message.source)
            Endpoint.internalBinaryWrite(message.source, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* flow.Endpoint destination = 9; */
        if (message.destination)
            Endpoint.internalBinaryWrite(message.destination, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* flow.FlowType Type = 10 [json_name = "Type"]; */
        if (message.type !== 0)
            writer.tag(10, WireType.Varint).int32(message.type);
        /* string node_name = 11; */
        if (message.nodeName !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.nodeName);
        /* repeated string node_labels = 37; */
        for (let i = 0; i < message.nodeLabels.length; i++)
            writer.tag(37, WireType.LengthDelimited).string(message.nodeLabels[i]);
        /* repeated string source_names = 13; */
        for (let i = 0; i < message.sourceNames.length; i++)
            writer.tag(13, WireType.LengthDelimited).string(message.sourceNames[i]);
        /* repeated string destination_names = 14; */
        for (let i = 0; i < message.destinationNames.length; i++)
            writer.tag(14, WireType.LengthDelimited).string(message.destinationNames[i]);
        /* flow.Layer7 l7 = 15; */
        if (message.l7)
            Layer7.internalBinaryWrite(message.l7, writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* bool reply = 16 [deprecated = true]; */
        if (message.reply !== false)
            writer.tag(16, WireType.Varint).bool(message.reply);
        /* flow.CiliumEventType event_type = 19; */
        if (message.eventType)
            CiliumEventType.internalBinaryWrite(message.eventType, writer.tag(19, WireType.LengthDelimited).fork(), options).join();
        /* flow.Service source_service = 20; */
        if (message.sourceService)
            Service.internalBinaryWrite(message.sourceService, writer.tag(20, WireType.LengthDelimited).fork(), options).join();
        /* flow.Service destination_service = 21; */
        if (message.destinationService)
            Service.internalBinaryWrite(message.destinationService, writer.tag(21, WireType.LengthDelimited).fork(), options).join();
        /* flow.TrafficDirection traffic_direction = 22; */
        if (message.trafficDirection !== 0)
            writer.tag(22, WireType.Varint).int32(message.trafficDirection);
        /* uint32 policy_match_type = 23; */
        if (message.policyMatchType !== 0)
            writer.tag(23, WireType.Varint).uint32(message.policyMatchType);
        /* flow.TraceObservationPoint trace_observation_point = 24; */
        if (message.traceObservationPoint !== 0)
            writer.tag(24, WireType.Varint).int32(message.traceObservationPoint);
        /* flow.TraceReason trace_reason = 36; */
        if (message.traceReason !== 0)
            writer.tag(36, WireType.Varint).int32(message.traceReason);
        /* flow.FileInfo file = 38; */
        if (message.file)
            FileInfo.internalBinaryWrite(message.file, writer.tag(38, WireType.LengthDelimited).fork(), options).join();
        /* flow.DropReason drop_reason_desc = 25; */
        if (message.dropReasonDesc !== 0)
            writer.tag(25, WireType.Varint).int32(message.dropReasonDesc);
        /* google.protobuf.BoolValue is_reply = 26; */
        if (message.isReply)
            BoolValue.internalBinaryWrite(message.isReply, writer.tag(26, WireType.LengthDelimited).fork(), options).join();
        /* flow.DebugCapturePoint debug_capture_point = 27; */
        if (message.debugCapturePoint !== 0)
            writer.tag(27, WireType.Varint).int32(message.debugCapturePoint);
        /* flow.NetworkInterface interface = 28; */
        if (message.interface)
            NetworkInterface.internalBinaryWrite(message.interface, writer.tag(28, WireType.LengthDelimited).fork(), options).join();
        /* uint32 proxy_port = 29; */
        if (message.proxyPort !== 0)
            writer.tag(29, WireType.Varint).uint32(message.proxyPort);
        /* flow.TraceContext trace_context = 30; */
        if (message.traceContext)
            TraceContext.internalBinaryWrite(message.traceContext, writer.tag(30, WireType.LengthDelimited).fork(), options).join();
        /* flow.SocketTranslationPoint sock_xlate_point = 31; */
        if (message.sockXlatePoint !== 0)
            writer.tag(31, WireType.Varint).int32(message.sockXlatePoint);
        /* uint64 socket_cookie = 32; */
        if (message.socketCookie !== 0)
            writer.tag(32, WireType.Varint).uint64(message.socketCookie);
        /* uint64 cgroup_id = 33; */
        if (message.cgroupId !== 0)
            writer.tag(33, WireType.Varint).uint64(message.cgroupId);
        /* string Summary = 100000 [deprecated = true, json_name = "Summary"]; */
        if (message.summary !== "")
            writer.tag(100000, WireType.LengthDelimited).string(message.summary);
        /* google.protobuf.Any extensions = 150000; */
        if (message.extensions)
            Any.internalBinaryWrite(message.extensions, writer.tag(150000, WireType.LengthDelimited).fork(), options).join();
        /* repeated flow.Policy egress_allowed_by = 21001; */
        for (let i = 0; i < message.egressAllowedBy.length; i++)
            Policy.internalBinaryWrite(message.egressAllowedBy[i], writer.tag(21001, WireType.LengthDelimited).fork(), options).join();
        /* repeated flow.Policy ingress_allowed_by = 21002; */
        for (let i = 0; i < message.ingressAllowedBy.length; i++)
            Policy.internalBinaryWrite(message.ingressAllowedBy[i], writer.tag(21002, WireType.LengthDelimited).fork(), options).join();
        /* repeated flow.Policy egress_denied_by = 21004; */
        for (let i = 0; i < message.egressDeniedBy.length; i++)
            Policy.internalBinaryWrite(message.egressDeniedBy[i], writer.tag(21004, WireType.LengthDelimited).fork(), options).join();
        /* repeated flow.Policy ingress_denied_by = 21005; */
        for (let i = 0; i < message.ingressDeniedBy.length; i++)
            Policy.internalBinaryWrite(message.ingressDeniedBy[i], writer.tag(21005, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message flow.Flow
 */
export const Flow = new Flow$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FileInfo$Type extends MessageType<FileInfo> {
    constructor() {
        super("flow.FileInfo", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "line", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<FileInfo>): FileInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.line = 0;
        if (value !== undefined)
            reflectionMergePartial<FileInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FileInfo): FileInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* uint32 line */ 2:
                    message.line = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FileInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* uint32 line = 2; */
        if (message.line !== 0)
            writer.tag(2, WireType.Varint).uint32(message.line);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message flow.FileInfo
 */
export const FileInfo = new FileInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Layer4$Type extends MessageType<Layer4> {
    constructor() {
        super("flow.Layer4", [
            { no: 1, name: "TCP", kind: "message", jsonName: "TCP", oneof: "protocol", T: () => TCP },
            { no: 2, name: "UDP", kind: "message", jsonName: "UDP", oneof: "protocol", T: () => UDP },
            { no: 3, name: "ICMPv4", kind: "message", jsonName: "ICMPv4", oneof: "protocol", T: () => ICMPv4 },
            { no: 4, name: "ICMPv6", kind: "message", jsonName: "ICMPv6", oneof: "protocol", T: () => ICMPv6 },
            { no: 5, name: "SCTP", kind: "message", jsonName: "SCTP", oneof: "protocol", T: () => SCTP }
        ]);
    }
    create(value?: PartialMessage<Layer4>): Layer4 {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.protocol = { oneofKind: undefined };
        if (value !== undefined)
            reflectionMergePartial<Layer4>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Layer4): Layer4 {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* flow.TCP TCP = 1 [json_name = "TCP"];*/ 1:
                    message.protocol = {
                        oneofKind: "tCP",
                        tCP: TCP.internalBinaryRead(reader, reader.uint32(), options, (message.protocol as any).tCP)
                    };
                    break;
                case /* flow.UDP UDP = 2 [json_name = "UDP"];*/ 2:
                    message.protocol = {
                        oneofKind: "uDP",
                        uDP: UDP.internalBinaryRead(reader, reader.uint32(), options, (message.protocol as any).uDP)
                    };
                    break;
                case /* flow.ICMPv4 ICMPv4 = 3 [json_name = "ICMPv4"];*/ 3:
                    message.protocol = {
                        oneofKind: "iCMPv4",
                        iCMPv4: ICMPv4.internalBinaryRead(reader, reader.uint32(), options, (message.protocol as any).iCMPv4)
                    };
                    break;
                case /* flow.ICMPv6 ICMPv6 = 4 [json_name = "ICMPv6"];*/ 4:
                    message.protocol = {
                        oneofKind: "iCMPv6",
                        iCMPv6: ICMPv6.internalBinaryRead(reader, reader.uint32(), options, (message.protocol as any).iCMPv6)
                    };
                    break;
                case /* flow.SCTP SCTP = 5 [json_name = "SCTP"];*/ 5:
                    message.protocol = {
                        oneofKind: "sCTP",
                        sCTP: SCTP.internalBinaryRead(reader, reader.uint32(), options, (message.protocol as any).sCTP)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Layer4, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* flow.TCP TCP = 1 [json_name = "TCP"]; */
        if (message.protocol.oneofKind === "tCP")
            TCP.internalBinaryWrite(message.protocol.tCP, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* flow.UDP UDP = 2 [json_name = "UDP"]; */
        if (message.protocol.oneofKind === "uDP")
            UDP.internalBinaryWrite(message.protocol.uDP, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* flow.ICMPv4 ICMPv4 = 3 [json_name = "ICMPv4"]; */
        if (message.protocol.oneofKind === "iCMPv4")
            ICMPv4.internalBinaryWrite(message.protocol.iCMPv4, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* flow.ICMPv6 ICMPv6 = 4 [json_name = "ICMPv6"]; */
        if (message.protocol.oneofKind === "iCMPv6")
            ICMPv6.internalBinaryWrite(message.protocol.iCMPv6, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* flow.SCTP SCTP = 5 [json_name = "SCTP"]; */
        if (message.protocol.oneofKind === "sCTP")
            SCTP.internalBinaryWrite(message.protocol.sCTP, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message flow.Layer4
 */
export const Layer4 = new Layer4$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Layer7$Type extends MessageType<Layer7> {
    constructor() {
        super("flow.Layer7", [
            { no: 1, name: "type", kind: "enum", T: () => ["flow.L7FlowType", L7FlowType] },
            { no: 2, name: "latency_ns", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 100, name: "dns", kind: "message", oneof: "record", T: () => DNS },
            { no: 101, name: "http", kind: "message", oneof: "record", T: () => HTTP },
            { no: 102, name: "kafka", kind: "message", oneof: "record", T: () => Kafka }
        ]);
    }
    create(value?: PartialMessage<Layer7>): Layer7 {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.type = 0;
        message.latencyNs = 0;
        message.record = { oneofKind: undefined };
        if (value !== undefined)
            reflectionMergePartial<Layer7>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Layer7): Layer7 {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* flow.L7FlowType type */ 1:
                    message.type = reader.int32();
                    break;
                case /* uint64 latency_ns */ 2:
                    message.latencyNs = reader.uint64().toNumber();
                    break;
                case /* flow.DNS dns */ 100:
                    message.record = {
                        oneofKind: "dns",
                        dns: DNS.internalBinaryRead(reader, reader.uint32(), options, (message.record as any).dns)
                    };
                    break;
                case /* flow.HTTP http */ 101:
                    message.record = {
                        oneofKind: "http",
                        http: HTTP.internalBinaryRead(reader, reader.uint32(), options, (message.record as any).http)
                    };
                    break;
                case /* flow.Kafka kafka */ 102:
                    message.record = {
                        oneofKind: "kafka",
                        kafka: Kafka.internalBinaryRead(reader, reader.uint32(), options, (message.record as any).kafka)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Layer7, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* flow.L7FlowType type = 1; */
        if (message.type !== 0)
            writer.tag(1, WireType.Varint).int32(message.type);
        /* uint64 latency_ns = 2; */
        if (message.latencyNs !== 0)
            writer.tag(2, WireType.Varint).uint64(message.latencyNs);
        /* flow.DNS dns = 100; */
        if (message.record.oneofKind === "dns")
            DNS.internalBinaryWrite(message.record.dns, writer.tag(100, WireType.LengthDelimited).fork(), options).join();
        /* flow.HTTP http = 101; */
        if (message.record.oneofKind === "http")
            HTTP.internalBinaryWrite(message.record.http, writer.tag(101, WireType.LengthDelimited).fork(), options).join();
        /* flow.Kafka kafka = 102; */
        if (message.record.oneofKind === "kafka")
            Kafka.internalBinaryWrite(message.record.kafka, writer.tag(102, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message flow.Layer7
 */
export const Layer7 = new Layer7$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TraceContext$Type extends MessageType<TraceContext> {
    constructor() {
        super("flow.TraceContext", [
            { no: 1, name: "parent", kind: "message", T: () => TraceParent }
        ]);
    }
    create(value?: PartialMessage<TraceContext>): TraceContext {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<TraceContext>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TraceContext): TraceContext {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* flow.TraceParent parent */ 1:
                    message.parent = TraceParent.internalBinaryRead(reader, reader.uint32(), options, message.parent);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TraceContext, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* flow.TraceParent parent = 1; */
        if (message.parent)
            TraceParent.internalBinaryWrite(message.parent, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message flow.TraceContext
 */
export const TraceContext = new TraceContext$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TraceParent$Type extends MessageType<TraceParent> {
    constructor() {
        super("flow.TraceParent", [
            { no: 1, name: "trace_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<TraceParent>): TraceParent {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.traceId = "";
        if (value !== undefined)
            reflectionMergePartial<TraceParent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TraceParent): TraceParent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string trace_id */ 1:
                    message.traceId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TraceParent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string trace_id = 1; */
        if (message.traceId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.traceId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message flow.TraceParent
 */
export const TraceParent = new TraceParent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Endpoint$Type extends MessageType<Endpoint> {
    constructor() {
        super("flow.Endpoint", [
            { no: 1, name: "ID", kind: "scalar", jsonName: "ID", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "identity", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 7, name: "cluster_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "namespace", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "labels", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "pod_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "workloads", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Workload }
        ]);
    }
    create(value?: PartialMessage<Endpoint>): Endpoint {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.iD = 0;
        message.identity = 0;
        message.clusterName = "";
        message.namespace = "";
        message.labels = [];
        message.podName = "";
        message.workloads = [];
        if (value !== undefined)
            reflectionMergePartial<Endpoint>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Endpoint): Endpoint {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 ID = 1 [json_name = "ID"];*/ 1:
                    message.iD = reader.uint32();
                    break;
                case /* uint32 identity */ 2:
                    message.identity = reader.uint32();
                    break;
                case /* string cluster_name */ 7:
                    message.clusterName = reader.string();
                    break;
                case /* string namespace */ 3:
                    message.namespace = reader.string();
                    break;
                case /* repeated string labels */ 4:
                    message.labels.push(reader.string());
                    break;
                case /* string pod_name */ 5:
                    message.podName = reader.string();
                    break;
                case /* repeated flow.Workload workloads */ 6:
                    message.workloads.push(Workload.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Endpoint, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 ID = 1 [json_name = "ID"]; */
        if (message.iD !== 0)
            writer.tag(1, WireType.Varint).uint32(message.iD);
        /* uint32 identity = 2; */
        if (message.identity !== 0)
            writer.tag(2, WireType.Varint).uint32(message.identity);
        /* string cluster_name = 7; */
        if (message.clusterName !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.clusterName);
        /* string namespace = 3; */
        if (message.namespace !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.namespace);
        /* repeated string labels = 4; */
        for (let i = 0; i < message.labels.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.labels[i]);
        /* string pod_name = 5; */
        if (message.podName !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.podName);
        /* repeated flow.Workload workloads = 6; */
        for (let i = 0; i < message.workloads.length; i++)
            Workload.internalBinaryWrite(message.workloads[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message flow.Endpoint
 */
export const Endpoint = new Endpoint$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Workload$Type extends MessageType<Workload> {
    constructor() {
        super("flow.Workload", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "kind", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Workload>): Workload {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.kind = "";
        if (value !== undefined)
            reflectionMergePartial<Workload>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Workload): Workload {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string kind */ 2:
                    message.kind = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Workload, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string kind = 2; */
        if (message.kind !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.kind);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message flow.Workload
 */
export const Workload = new Workload$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TCP$Type extends MessageType<TCP> {
    constructor() {
        super("flow.TCP", [
            { no: 1, name: "source_port", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "destination_port", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "flags", kind: "message", T: () => TCPFlags }
        ]);
    }
    create(value?: PartialMessage<TCP>): TCP {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.sourcePort = 0;
        message.destinationPort = 0;
        if (value !== undefined)
            reflectionMergePartial<TCP>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TCP): TCP {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 source_port */ 1:
                    message.sourcePort = reader.uint32();
                    break;
                case /* uint32 destination_port */ 2:
                    message.destinationPort = reader.uint32();
                    break;
                case /* flow.TCPFlags flags */ 3:
                    message.flags = TCPFlags.internalBinaryRead(reader, reader.uint32(), options, message.flags);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TCP, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 source_port = 1; */
        if (message.sourcePort !== 0)
            writer.tag(1, WireType.Varint).uint32(message.sourcePort);
        /* uint32 destination_port = 2; */
        if (message.destinationPort !== 0)
            writer.tag(2, WireType.Varint).uint32(message.destinationPort);
        /* flow.TCPFlags flags = 3; */
        if (message.flags)
            TCPFlags.internalBinaryWrite(message.flags, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message flow.TCP
 */
export const TCP = new TCP$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IP$Type extends MessageType<IP> {
    constructor() {
        super("flow.IP", [
            { no: 1, name: "source", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "source_xlated", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "destination", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "ipVersion", kind: "enum", T: () => ["flow.IPVersion", IPVersion] },
            { no: 4, name: "encrypted", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<IP>): IP {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.source = "";
        message.sourceXlated = "";
        message.destination = "";
        message.ipVersion = 0;
        message.encrypted = false;
        if (value !== undefined)
            reflectionMergePartial<IP>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IP): IP {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string source */ 1:
                    message.source = reader.string();
                    break;
                case /* string source_xlated */ 5:
                    message.sourceXlated = reader.string();
                    break;
                case /* string destination */ 2:
                    message.destination = reader.string();
                    break;
                case /* flow.IPVersion ipVersion */ 3:
                    message.ipVersion = reader.int32();
                    break;
                case /* bool encrypted */ 4:
                    message.encrypted = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IP, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string source = 1; */
        if (message.source !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.source);
        /* string source_xlated = 5; */
        if (message.sourceXlated !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.sourceXlated);
        /* string destination = 2; */
        if (message.destination !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.destination);
        /* flow.IPVersion ipVersion = 3; */
        if (message.ipVersion !== 0)
            writer.tag(3, WireType.Varint).int32(message.ipVersion);
        /* bool encrypted = 4; */
        if (message.encrypted !== false)
            writer.tag(4, WireType.Varint).bool(message.encrypted);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message flow.IP
 */
export const IP = new IP$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Ethernet$Type extends MessageType<Ethernet> {
    constructor() {
        super("flow.Ethernet", [
            { no: 1, name: "source", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "destination", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Ethernet>): Ethernet {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.source = "";
        message.destination = "";
        if (value !== undefined)
            reflectionMergePartial<Ethernet>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Ethernet): Ethernet {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string source */ 1:
                    message.source = reader.string();
                    break;
                case /* string destination */ 2:
                    message.destination = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Ethernet, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string source = 1; */
        if (message.source !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.source);
        /* string destination = 2; */
        if (message.destination !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.destination);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message flow.Ethernet
 */
export const Ethernet = new Ethernet$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TCPFlags$Type extends MessageType<TCPFlags> {
    constructor() {
        super("flow.TCPFlags", [
            { no: 1, name: "FIN", kind: "scalar", jsonName: "FIN", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "SYN", kind: "scalar", jsonName: "SYN", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "RST", kind: "scalar", jsonName: "RST", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "PSH", kind: "scalar", jsonName: "PSH", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "ACK", kind: "scalar", jsonName: "ACK", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "URG", kind: "scalar", jsonName: "URG", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "ECE", kind: "scalar", jsonName: "ECE", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "CWR", kind: "scalar", jsonName: "CWR", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "NS", kind: "scalar", jsonName: "NS", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<TCPFlags>): TCPFlags {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.fIN = false;
        message.sYN = false;
        message.rST = false;
        message.pSH = false;
        message.aCK = false;
        message.uRG = false;
        message.eCE = false;
        message.cWR = false;
        message.nS = false;
        if (value !== undefined)
            reflectionMergePartial<TCPFlags>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TCPFlags): TCPFlags {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool FIN = 1 [json_name = "FIN"];*/ 1:
                    message.fIN = reader.bool();
                    break;
                case /* bool SYN = 2 [json_name = "SYN"];*/ 2:
                    message.sYN = reader.bool();
                    break;
                case /* bool RST = 3 [json_name = "RST"];*/ 3:
                    message.rST = reader.bool();
                    break;
                case /* bool PSH = 4 [json_name = "PSH"];*/ 4:
                    message.pSH = reader.bool();
                    break;
                case /* bool ACK = 5 [json_name = "ACK"];*/ 5:
                    message.aCK = reader.bool();
                    break;
                case /* bool URG = 6 [json_name = "URG"];*/ 6:
                    message.uRG = reader.bool();
                    break;
                case /* bool ECE = 7 [json_name = "ECE"];*/ 7:
                    message.eCE = reader.bool();
                    break;
                case /* bool CWR = 8 [json_name = "CWR"];*/ 8:
                    message.cWR = reader.bool();
                    break;
                case /* bool NS = 9 [json_name = "NS"];*/ 9:
                    message.nS = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TCPFlags, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool FIN = 1 [json_name = "FIN"]; */
        if (message.fIN !== false)
            writer.tag(1, WireType.Varint).bool(message.fIN);
        /* bool SYN = 2 [json_name = "SYN"]; */
        if (message.sYN !== false)
            writer.tag(2, WireType.Varint).bool(message.sYN);
        /* bool RST = 3 [json_name = "RST"]; */
        if (message.rST !== false)
            writer.tag(3, WireType.Varint).bool(message.rST);
        /* bool PSH = 4 [json_name = "PSH"]; */
        if (message.pSH !== false)
            writer.tag(4, WireType.Varint).bool(message.pSH);
        /* bool ACK = 5 [json_name = "ACK"]; */
        if (message.aCK !== false)
            writer.tag(5, WireType.Varint).bool(message.aCK);
        /* bool URG = 6 [json_name = "URG"]; */
        if (message.uRG !== false)
            writer.tag(6, WireType.Varint).bool(message.uRG);
        /* bool ECE = 7 [json_name = "ECE"]; */
        if (message.eCE !== false)
            writer.tag(7, WireType.Varint).bool(message.eCE);
        /* bool CWR = 8 [json_name = "CWR"]; */
        if (message.cWR !== false)
            writer.tag(8, WireType.Varint).bool(message.cWR);
        /* bool NS = 9 [json_name = "NS"]; */
        if (message.nS !== false)
            writer.tag(9, WireType.Varint).bool(message.nS);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message flow.TCPFlags
 */
export const TCPFlags = new TCPFlags$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UDP$Type extends MessageType<UDP> {
    constructor() {
        super("flow.UDP", [
            { no: 1, name: "source_port", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "destination_port", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<UDP>): UDP {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.sourcePort = 0;
        message.destinationPort = 0;
        if (value !== undefined)
            reflectionMergePartial<UDP>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UDP): UDP {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 source_port */ 1:
                    message.sourcePort = reader.uint32();
                    break;
                case /* uint32 destination_port */ 2:
                    message.destinationPort = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UDP, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 source_port = 1; */
        if (message.sourcePort !== 0)
            writer.tag(1, WireType.Varint).uint32(message.sourcePort);
        /* uint32 destination_port = 2; */
        if (message.destinationPort !== 0)
            writer.tag(2, WireType.Varint).uint32(message.destinationPort);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message flow.UDP
 */
export const UDP = new UDP$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SCTP$Type extends MessageType<SCTP> {
    constructor() {
        super("flow.SCTP", [
            { no: 1, name: "source_port", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "destination_port", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<SCTP>): SCTP {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.sourcePort = 0;
        message.destinationPort = 0;
        if (value !== undefined)
            reflectionMergePartial<SCTP>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SCTP): SCTP {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 source_port */ 1:
                    message.sourcePort = reader.uint32();
                    break;
                case /* uint32 destination_port */ 2:
                    message.destinationPort = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SCTP, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 source_port = 1; */
        if (message.sourcePort !== 0)
            writer.tag(1, WireType.Varint).uint32(message.sourcePort);
        /* uint32 destination_port = 2; */
        if (message.destinationPort !== 0)
            writer.tag(2, WireType.Varint).uint32(message.destinationPort);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message flow.SCTP
 */
export const SCTP = new SCTP$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ICMPv4$Type extends MessageType<ICMPv4> {
    constructor() {
        super("flow.ICMPv4", [
            { no: 1, name: "type", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "code", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<ICMPv4>): ICMPv4 {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.type = 0;
        message.code = 0;
        if (value !== undefined)
            reflectionMergePartial<ICMPv4>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ICMPv4): ICMPv4 {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 type */ 1:
                    message.type = reader.uint32();
                    break;
                case /* uint32 code */ 2:
                    message.code = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ICMPv4, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 type = 1; */
        if (message.type !== 0)
            writer.tag(1, WireType.Varint).uint32(message.type);
        /* uint32 code = 2; */
        if (message.code !== 0)
            writer.tag(2, WireType.Varint).uint32(message.code);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message flow.ICMPv4
 */
export const ICMPv4 = new ICMPv4$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ICMPv6$Type extends MessageType<ICMPv6> {
    constructor() {
        super("flow.ICMPv6", [
            { no: 1, name: "type", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "code", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<ICMPv6>): ICMPv6 {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.type = 0;
        message.code = 0;
        if (value !== undefined)
            reflectionMergePartial<ICMPv6>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ICMPv6): ICMPv6 {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 type */ 1:
                    message.type = reader.uint32();
                    break;
                case /* uint32 code */ 2:
                    message.code = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ICMPv6, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 type = 1; */
        if (message.type !== 0)
            writer.tag(1, WireType.Varint).uint32(message.type);
        /* uint32 code = 2; */
        if (message.code !== 0)
            writer.tag(2, WireType.Varint).uint32(message.code);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message flow.ICMPv6
 */
export const ICMPv6 = new ICMPv6$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Policy$Type extends MessageType<Policy> {
    constructor() {
        super("flow.Policy", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "namespace", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "labels", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "revision", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "kind", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Policy>): Policy {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.namespace = "";
        message.labels = [];
        message.revision = 0;
        message.kind = "";
        if (value !== undefined)
            reflectionMergePartial<Policy>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Policy): Policy {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string namespace */ 2:
                    message.namespace = reader.string();
                    break;
                case /* repeated string labels */ 3:
                    message.labels.push(reader.string());
                    break;
                case /* uint64 revision */ 4:
                    message.revision = reader.uint64().toNumber();
                    break;
                case /* string kind */ 5:
                    message.kind = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Policy, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string namespace = 2; */
        if (message.namespace !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.namespace);
        /* repeated string labels = 3; */
        for (let i = 0; i < message.labels.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.labels[i]);
        /* uint64 revision = 4; */
        if (message.revision !== 0)
            writer.tag(4, WireType.Varint).uint64(message.revision);
        /* string kind = 5; */
        if (message.kind !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.kind);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message flow.Policy
 */
export const Policy = new Policy$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EventTypeFilter$Type extends MessageType<EventTypeFilter> {
    constructor() {
        super("flow.EventTypeFilter", [
            { no: 1, name: "type", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "match_sub_type", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "sub_type", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<EventTypeFilter>): EventTypeFilter {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.type = 0;
        message.matchSubType = false;
        message.subType = 0;
        if (value !== undefined)
            reflectionMergePartial<EventTypeFilter>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EventTypeFilter): EventTypeFilter {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 type */ 1:
                    message.type = reader.int32();
                    break;
                case /* bool match_sub_type */ 2:
                    message.matchSubType = reader.bool();
                    break;
                case /* int32 sub_type */ 3:
                    message.subType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EventTypeFilter, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 type = 1; */
        if (message.type !== 0)
            writer.tag(1, WireType.Varint).int32(message.type);
        /* bool match_sub_type = 2; */
        if (message.matchSubType !== false)
            writer.tag(2, WireType.Varint).bool(message.matchSubType);
        /* int32 sub_type = 3; */
        if (message.subType !== 0)
            writer.tag(3, WireType.Varint).int32(message.subType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message flow.EventTypeFilter
 */
export const EventTypeFilter = new EventTypeFilter$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CiliumEventType$Type extends MessageType<CiliumEventType> {
    constructor() {
        super("flow.CiliumEventType", [
            { no: 1, name: "type", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "sub_type", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<CiliumEventType>): CiliumEventType {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.type = 0;
        message.subType = 0;
        if (value !== undefined)
            reflectionMergePartial<CiliumEventType>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CiliumEventType): CiliumEventType {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 type */ 1:
                    message.type = reader.int32();
                    break;
                case /* int32 sub_type */ 2:
                    message.subType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CiliumEventType, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 type = 1; */
        if (message.type !== 0)
            writer.tag(1, WireType.Varint).int32(message.type);
        /* int32 sub_type = 2; */
        if (message.subType !== 0)
            writer.tag(2, WireType.Varint).int32(message.subType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message flow.CiliumEventType
 */
export const CiliumEventType = new CiliumEventType$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FlowFilter$Type extends MessageType<FlowFilter> {
    constructor() {
        super("flow.FlowFilter", [
            { no: 29, name: "uuid", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 1, name: "source_ip", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 34, name: "source_ip_xlated", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "source_pod", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "source_fqdn", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "source_label", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "source_service", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 26, name: "source_workload", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Workload },
            { no: 37, name: "source_cluster_name", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "destination_ip", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "destination_pod", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "destination_fqdn", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "destination_label", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "destination_service", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 27, name: "destination_workload", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Workload },
            { no: 38, name: "destination_cluster_name", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 30, name: "traffic_direction", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["flow.TrafficDirection", TrafficDirection] },
            { no: 5, name: "verdict", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["flow.Verdict", Verdict] },
            { no: 33, name: "drop_reason_desc", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["flow.DropReason", DropReason] },
            { no: 35, name: "interface", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => NetworkInterface },
            { no: 6, name: "event_type", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => EventTypeFilter },
            { no: 9, name: "http_status_code", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "protocol", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "source_port", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "destination_port", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "reply", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 8 /*ScalarType.BOOL*/ },
            { no: 18, name: "dns_query", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 19, name: "source_identity", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 13 /*ScalarType.UINT32*/ },
            { no: 20, name: "destination_identity", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 13 /*ScalarType.UINT32*/ },
            { no: 21, name: "http_method", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 22, name: "http_path", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 31, name: "http_url", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 32, name: "http_header", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => HTTPHeader },
            { no: 23, name: "tcp_flags", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TCPFlags },
            { no: 24, name: "node_name", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 36, name: "node_labels", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 25, name: "ip_version", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["flow.IPVersion", IPVersion] },
            { no: 28, name: "trace_id", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 999, name: "experimental", kind: "message", T: () => FlowFilter_Experimental }
        ]);
    }
    create(value?: PartialMessage<FlowFilter>): FlowFilter {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uuid = [];
        message.sourceIp = [];
        message.sourceIpXlated = [];
        message.sourcePod = [];
        message.sourceFqdn = [];
        message.sourceLabel = [];
        message.sourceService = [];
        message.sourceWorkload = [];
        message.sourceClusterName = [];
        message.destinationIp = [];
        message.destinationPod = [];
        message.destinationFqdn = [];
        message.destinationLabel = [];
        message.destinationService = [];
        message.destinationWorkload = [];
        message.destinationClusterName = [];
        message.trafficDirection = [];
        message.verdict = [];
        message.dropReasonDesc = [];
        message.interface = [];
        message.eventType = [];
        message.httpStatusCode = [];
        message.protocol = [];
        message.sourcePort = [];
        message.destinationPort = [];
        message.reply = [];
        message.dnsQuery = [];
        message.sourceIdentity = [];
        message.destinationIdentity = [];
        message.httpMethod = [];
        message.httpPath = [];
        message.httpUrl = [];
        message.httpHeader = [];
        message.tcpFlags = [];
        message.nodeName = [];
        message.nodeLabels = [];
        message.ipVersion = [];
        message.traceId = [];
        if (value !== undefined)
            reflectionMergePartial<FlowFilter>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FlowFilter): FlowFilter {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string uuid */ 29:
                    message.uuid.push(reader.string());
                    break;
                case /* repeated string source_ip */ 1:
                    message.sourceIp.push(reader.string());
                    break;
                case /* repeated string source_ip_xlated */ 34:
                    message.sourceIpXlated.push(reader.string());
                    break;
                case /* repeated string source_pod */ 2:
                    message.sourcePod.push(reader.string());
                    break;
                case /* repeated string source_fqdn */ 7:
                    message.sourceFqdn.push(reader.string());
                    break;
                case /* repeated string source_label */ 10:
                    message.sourceLabel.push(reader.string());
                    break;
                case /* repeated string source_service */ 16:
                    message.sourceService.push(reader.string());
                    break;
                case /* repeated flow.Workload source_workload */ 26:
                    message.sourceWorkload.push(Workload.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated string source_cluster_name */ 37:
                    message.sourceClusterName.push(reader.string());
                    break;
                case /* repeated string destination_ip */ 3:
                    message.destinationIp.push(reader.string());
                    break;
                case /* repeated string destination_pod */ 4:
                    message.destinationPod.push(reader.string());
                    break;
                case /* repeated string destination_fqdn */ 8:
                    message.destinationFqdn.push(reader.string());
                    break;
                case /* repeated string destination_label */ 11:
                    message.destinationLabel.push(reader.string());
                    break;
                case /* repeated string destination_service */ 17:
                    message.destinationService.push(reader.string());
                    break;
                case /* repeated flow.Workload destination_workload */ 27:
                    message.destinationWorkload.push(Workload.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated string destination_cluster_name */ 38:
                    message.destinationClusterName.push(reader.string());
                    break;
                case /* repeated flow.TrafficDirection traffic_direction */ 30:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.trafficDirection.push(reader.int32());
                    else
                        message.trafficDirection.push(reader.int32());
                    break;
                case /* repeated flow.Verdict verdict */ 5:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.verdict.push(reader.int32());
                    else
                        message.verdict.push(reader.int32());
                    break;
                case /* repeated flow.DropReason drop_reason_desc */ 33:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.dropReasonDesc.push(reader.int32());
                    else
                        message.dropReasonDesc.push(reader.int32());
                    break;
                case /* repeated flow.NetworkInterface interface */ 35:
                    message.interface.push(NetworkInterface.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated flow.EventTypeFilter event_type */ 6:
                    message.eventType.push(EventTypeFilter.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated string http_status_code */ 9:
                    message.httpStatusCode.push(reader.string());
                    break;
                case /* repeated string protocol */ 12:
                    message.protocol.push(reader.string());
                    break;
                case /* repeated string source_port */ 13:
                    message.sourcePort.push(reader.string());
                    break;
                case /* repeated string destination_port */ 14:
                    message.destinationPort.push(reader.string());
                    break;
                case /* repeated bool reply */ 15:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.reply.push(reader.bool());
                    else
                        message.reply.push(reader.bool());
                    break;
                case /* repeated string dns_query */ 18:
                    message.dnsQuery.push(reader.string());
                    break;
                case /* repeated uint32 source_identity */ 19:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.sourceIdentity.push(reader.uint32());
                    else
                        message.sourceIdentity.push(reader.uint32());
                    break;
                case /* repeated uint32 destination_identity */ 20:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.destinationIdentity.push(reader.uint32());
                    else
                        message.destinationIdentity.push(reader.uint32());
                    break;
                case /* repeated string http_method */ 21:
                    message.httpMethod.push(reader.string());
                    break;
                case /* repeated string http_path */ 22:
                    message.httpPath.push(reader.string());
                    break;
                case /* repeated string http_url */ 31:
                    message.httpUrl.push(reader.string());
                    break;
                case /* repeated flow.HTTPHeader http_header */ 32:
                    message.httpHeader.push(HTTPHeader.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated flow.TCPFlags tcp_flags */ 23:
                    message.tcpFlags.push(TCPFlags.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated string node_name */ 24:
                    message.nodeName.push(reader.string());
                    break;
                case /* repeated string node_labels */ 36:
                    message.nodeLabels.push(reader.string());
                    break;
                case /* repeated flow.IPVersion ip_version */ 25:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.ipVersion.push(reader.int32());
                    else
                        message.ipVersion.push(reader.int32());
                    break;
                case /* repeated string trace_id */ 28:
                    message.traceId.push(reader.string());
                    break;
                case /* flow.FlowFilter.Experimental experimental */ 999:
                    message.experimental = FlowFilter_Experimental.internalBinaryRead(reader, reader.uint32(), options, message.experimental);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FlowFilter, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string uuid = 29; */
        for (let i = 0; i < message.uuid.length; i++)
            writer.tag(29, WireType.LengthDelimited).string(message.uuid[i]);
        /* repeated string source_ip = 1; */
        for (let i = 0; i < message.sourceIp.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.sourceIp[i]);
        /* repeated string source_ip_xlated = 34; */
        for (let i = 0; i < message.sourceIpXlated.length; i++)
            writer.tag(34, WireType.LengthDelimited).string(message.sourceIpXlated[i]);
        /* repeated string source_pod = 2; */
        for (let i = 0; i < message.sourcePod.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.sourcePod[i]);
        /* repeated string source_fqdn = 7; */
        for (let i = 0; i < message.sourceFqdn.length; i++)
            writer.tag(7, WireType.LengthDelimited).string(message.sourceFqdn[i]);
        /* repeated string source_label = 10; */
        for (let i = 0; i < message.sourceLabel.length; i++)
            writer.tag(10, WireType.LengthDelimited).string(message.sourceLabel[i]);
        /* repeated string source_service = 16; */
        for (let i = 0; i < message.sourceService.length; i++)
            writer.tag(16, WireType.LengthDelimited).string(message.sourceService[i]);
        /* repeated flow.Workload source_workload = 26; */
        for (let i = 0; i < message.sourceWorkload.length; i++)
            Workload.internalBinaryWrite(message.sourceWorkload[i], writer.tag(26, WireType.LengthDelimited).fork(), options).join();
        /* repeated string source_cluster_name = 37; */
        for (let i = 0; i < message.sourceClusterName.length; i++)
            writer.tag(37, WireType.LengthDelimited).string(message.sourceClusterName[i]);
        /* repeated string destination_ip = 3; */
        for (let i = 0; i < message.destinationIp.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.destinationIp[i]);
        /* repeated string destination_pod = 4; */
        for (let i = 0; i < message.destinationPod.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.destinationPod[i]);
        /* repeated string destination_fqdn = 8; */
        for (let i = 0; i < message.destinationFqdn.length; i++)
            writer.tag(8, WireType.LengthDelimited).string(message.destinationFqdn[i]);
        /* repeated string destination_label = 11; */
        for (let i = 0; i < message.destinationLabel.length; i++)
            writer.tag(11, WireType.LengthDelimited).string(message.destinationLabel[i]);
        /* repeated string destination_service = 17; */
        for (let i = 0; i < message.destinationService.length; i++)
            writer.tag(17, WireType.LengthDelimited).string(message.destinationService[i]);
        /* repeated flow.Workload destination_workload = 27; */
        for (let i = 0; i < message.destinationWorkload.length; i++)
            Workload.internalBinaryWrite(message.destinationWorkload[i], writer.tag(27, WireType.LengthDelimited).fork(), options).join();
        /* repeated string destination_cluster_name = 38; */
        for (let i = 0; i < message.destinationClusterName.length; i++)
            writer.tag(38, WireType.LengthDelimited).string(message.destinationClusterName[i]);
        /* repeated flow.TrafficDirection traffic_direction = 30; */
        if (message.trafficDirection.length) {
            writer.tag(30, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.trafficDirection.length; i++)
                writer.int32(message.trafficDirection[i]);
            writer.join();
        }
        /* repeated flow.Verdict verdict = 5; */
        if (message.verdict.length) {
            writer.tag(5, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.verdict.length; i++)
                writer.int32(message.verdict[i]);
            writer.join();
        }
        /* repeated flow.DropReason drop_reason_desc = 33; */
        if (message.dropReasonDesc.length) {
            writer.tag(33, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.dropReasonDesc.length; i++)
                writer.int32(message.dropReasonDesc[i]);
            writer.join();
        }
        /* repeated flow.NetworkInterface interface = 35; */
        for (let i = 0; i < message.interface.length; i++)
            NetworkInterface.internalBinaryWrite(message.interface[i], writer.tag(35, WireType.LengthDelimited).fork(), options).join();
        /* repeated flow.EventTypeFilter event_type = 6; */
        for (let i = 0; i < message.eventType.length; i++)
            EventTypeFilter.internalBinaryWrite(message.eventType[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* repeated string http_status_code = 9; */
        for (let i = 0; i < message.httpStatusCode.length; i++)
            writer.tag(9, WireType.LengthDelimited).string(message.httpStatusCode[i]);
        /* repeated string protocol = 12; */
        for (let i = 0; i < message.protocol.length; i++)
            writer.tag(12, WireType.LengthDelimited).string(message.protocol[i]);
        /* repeated string source_port = 13; */
        for (let i = 0; i < message.sourcePort.length; i++)
            writer.tag(13, WireType.LengthDelimited).string(message.sourcePort[i]);
        /* repeated string destination_port = 14; */
        for (let i = 0; i < message.destinationPort.length; i++)
            writer.tag(14, WireType.LengthDelimited).string(message.destinationPort[i]);
        /* repeated bool reply = 15; */
        if (message.reply.length) {
            writer.tag(15, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.reply.length; i++)
                writer.bool(message.reply[i]);
            writer.join();
        }
        /* repeated string dns_query = 18; */
        for (let i = 0; i < message.dnsQuery.length; i++)
            writer.tag(18, WireType.LengthDelimited).string(message.dnsQuery[i]);
        /* repeated uint32 source_identity = 19; */
        if (message.sourceIdentity.length) {
            writer.tag(19, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.sourceIdentity.length; i++)
                writer.uint32(message.sourceIdentity[i]);
            writer.join();
        }
        /* repeated uint32 destination_identity = 20; */
        if (message.destinationIdentity.length) {
            writer.tag(20, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.destinationIdentity.length; i++)
                writer.uint32(message.destinationIdentity[i]);
            writer.join();
        }
        /* repeated string http_method = 21; */
        for (let i = 0; i < message.httpMethod.length; i++)
            writer.tag(21, WireType.LengthDelimited).string(message.httpMethod[i]);
        /* repeated string http_path = 22; */
        for (let i = 0; i < message.httpPath.length; i++)
            writer.tag(22, WireType.LengthDelimited).string(message.httpPath[i]);
        /* repeated string http_url = 31; */
        for (let i = 0; i < message.httpUrl.length; i++)
            writer.tag(31, WireType.LengthDelimited).string(message.httpUrl[i]);
        /* repeated flow.HTTPHeader http_header = 32; */
        for (let i = 0; i < message.httpHeader.length; i++)
            HTTPHeader.internalBinaryWrite(message.httpHeader[i], writer.tag(32, WireType.LengthDelimited).fork(), options).join();
        /* repeated flow.TCPFlags tcp_flags = 23; */
        for (let i = 0; i < message.tcpFlags.length; i++)
            TCPFlags.internalBinaryWrite(message.tcpFlags[i], writer.tag(23, WireType.LengthDelimited).fork(), options).join();
        /* repeated string node_name = 24; */
        for (let i = 0; i < message.nodeName.length; i++)
            writer.tag(24, WireType.LengthDelimited).string(message.nodeName[i]);
        /* repeated string node_labels = 36; */
        for (let i = 0; i < message.nodeLabels.length; i++)
            writer.tag(36, WireType.LengthDelimited).string(message.nodeLabels[i]);
        /* repeated flow.IPVersion ip_version = 25; */
        if (message.ipVersion.length) {
            writer.tag(25, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.ipVersion.length; i++)
                writer.int32(message.ipVersion[i]);
            writer.join();
        }
        /* repeated string trace_id = 28; */
        for (let i = 0; i < message.traceId.length; i++)
            writer.tag(28, WireType.LengthDelimited).string(message.traceId[i]);
        /* flow.FlowFilter.Experimental experimental = 999; */
        if (message.experimental)
            FlowFilter_Experimental.internalBinaryWrite(message.experimental, writer.tag(999, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message flow.FlowFilter
 */
export const FlowFilter = new FlowFilter$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FlowFilter_Experimental$Type extends MessageType<FlowFilter_Experimental> {
    constructor() {
        super("flow.FlowFilter.Experimental", [
            { no: 1, name: "cel_expression", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<FlowFilter_Experimental>): FlowFilter_Experimental {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.celExpression = [];
        if (value !== undefined)
            reflectionMergePartial<FlowFilter_Experimental>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FlowFilter_Experimental): FlowFilter_Experimental {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string cel_expression */ 1:
                    message.celExpression.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FlowFilter_Experimental, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string cel_expression = 1; */
        for (let i = 0; i < message.celExpression.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.celExpression[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message flow.FlowFilter.Experimental
 */
export const FlowFilter_Experimental = new FlowFilter_Experimental$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DNS$Type extends MessageType<DNS> {
    constructor() {
        super("flow.DNS", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "ips", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "ttl", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "cnames", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "observation_source", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "rcode", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 7, name: "qtypes", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "rrtypes", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DNS>): DNS {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.query = "";
        message.ips = [];
        message.ttl = 0;
        message.cnames = [];
        message.observationSource = "";
        message.rcode = 0;
        message.qtypes = [];
        message.rrtypes = [];
        if (value !== undefined)
            reflectionMergePartial<DNS>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DNS): DNS {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* repeated string ips */ 2:
                    message.ips.push(reader.string());
                    break;
                case /* uint32 ttl */ 3:
                    message.ttl = reader.uint32();
                    break;
                case /* repeated string cnames */ 4:
                    message.cnames.push(reader.string());
                    break;
                case /* string observation_source */ 5:
                    message.observationSource = reader.string();
                    break;
                case /* uint32 rcode */ 6:
                    message.rcode = reader.uint32();
                    break;
                case /* repeated string qtypes */ 7:
                    message.qtypes.push(reader.string());
                    break;
                case /* repeated string rrtypes */ 8:
                    message.rrtypes.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DNS, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* repeated string ips = 2; */
        for (let i = 0; i < message.ips.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.ips[i]);
        /* uint32 ttl = 3; */
        if (message.ttl !== 0)
            writer.tag(3, WireType.Varint).uint32(message.ttl);
        /* repeated string cnames = 4; */
        for (let i = 0; i < message.cnames.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.cnames[i]);
        /* string observation_source = 5; */
        if (message.observationSource !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.observationSource);
        /* uint32 rcode = 6; */
        if (message.rcode !== 0)
            writer.tag(6, WireType.Varint).uint32(message.rcode);
        /* repeated string qtypes = 7; */
        for (let i = 0; i < message.qtypes.length; i++)
            writer.tag(7, WireType.LengthDelimited).string(message.qtypes[i]);
        /* repeated string rrtypes = 8; */
        for (let i = 0; i < message.rrtypes.length; i++)
            writer.tag(8, WireType.LengthDelimited).string(message.rrtypes[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message flow.DNS
 */
export const DNS = new DNS$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HTTPHeader$Type extends MessageType<HTTPHeader> {
    constructor() {
        super("flow.HTTPHeader", [
            { no: 1, name: "key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<HTTPHeader>): HTTPHeader {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.key = "";
        message.value = "";
        if (value !== undefined)
            reflectionMergePartial<HTTPHeader>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: HTTPHeader): HTTPHeader {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string key */ 1:
                    message.key = reader.string();
                    break;
                case /* string value */ 2:
                    message.value = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: HTTPHeader, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string key = 1; */
        if (message.key !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.key);
        /* string value = 2; */
        if (message.value !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.value);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message flow.HTTPHeader
 */
export const HTTPHeader = new HTTPHeader$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HTTP$Type extends MessageType<HTTP> {
    constructor() {
        super("flow.HTTP", [
            { no: 1, name: "code", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "method", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "protocol", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "headers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => HTTPHeader }
        ]);
    }
    create(value?: PartialMessage<HTTP>): HTTP {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.code = 0;
        message.method = "";
        message.url = "";
        message.protocol = "";
        message.headers = [];
        if (value !== undefined)
            reflectionMergePartial<HTTP>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: HTTP): HTTP {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 code */ 1:
                    message.code = reader.uint32();
                    break;
                case /* string method */ 2:
                    message.method = reader.string();
                    break;
                case /* string url */ 3:
                    message.url = reader.string();
                    break;
                case /* string protocol */ 4:
                    message.protocol = reader.string();
                    break;
                case /* repeated flow.HTTPHeader headers */ 5:
                    message.headers.push(HTTPHeader.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: HTTP, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 code = 1; */
        if (message.code !== 0)
            writer.tag(1, WireType.Varint).uint32(message.code);
        /* string method = 2; */
        if (message.method !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.method);
        /* string url = 3; */
        if (message.url !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.url);
        /* string protocol = 4; */
        if (message.protocol !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.protocol);
        /* repeated flow.HTTPHeader headers = 5; */
        for (let i = 0; i < message.headers.length; i++)
            HTTPHeader.internalBinaryWrite(message.headers[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message flow.HTTP
 */
export const HTTP = new HTTP$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Kafka$Type extends MessageType<Kafka> {
    constructor() {
        super("flow.Kafka", [
            { no: 1, name: "error_code", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "api_version", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "api_key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "correlation_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "topic", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Kafka>): Kafka {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.errorCode = 0;
        message.apiVersion = 0;
        message.apiKey = "";
        message.correlationId = 0;
        message.topic = "";
        if (value !== undefined)
            reflectionMergePartial<Kafka>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Kafka): Kafka {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 error_code */ 1:
                    message.errorCode = reader.int32();
                    break;
                case /* int32 api_version */ 2:
                    message.apiVersion = reader.int32();
                    break;
                case /* string api_key */ 3:
                    message.apiKey = reader.string();
                    break;
                case /* int32 correlation_id */ 4:
                    message.correlationId = reader.int32();
                    break;
                case /* string topic */ 5:
                    message.topic = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Kafka, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 error_code = 1; */
        if (message.errorCode !== 0)
            writer.tag(1, WireType.Varint).int32(message.errorCode);
        /* int32 api_version = 2; */
        if (message.apiVersion !== 0)
            writer.tag(2, WireType.Varint).int32(message.apiVersion);
        /* string api_key = 3; */
        if (message.apiKey !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.apiKey);
        /* int32 correlation_id = 4; */
        if (message.correlationId !== 0)
            writer.tag(4, WireType.Varint).int32(message.correlationId);
        /* string topic = 5; */
        if (message.topic !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.topic);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message flow.Kafka
 */
export const Kafka = new Kafka$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Service$Type extends MessageType<Service> {
    constructor() {
        super("flow.Service", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "namespace", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Service>): Service {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.namespace = "";
        if (value !== undefined)
            reflectionMergePartial<Service>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Service): Service {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string namespace */ 2:
                    message.namespace = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Service, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string namespace = 2; */
        if (message.namespace !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.namespace);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message flow.Service
 */
export const Service = new Service$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LostEvent$Type extends MessageType<LostEvent> {
    constructor() {
        super("flow.LostEvent", [
            { no: 1, name: "source", kind: "enum", T: () => ["flow.LostEventSource", LostEventSource] },
            { no: 2, name: "num_events_lost", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "cpu", kind: "message", T: () => Int32Value }
        ]);
    }
    create(value?: PartialMessage<LostEvent>): LostEvent {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.source = 0;
        message.numEventsLost = 0;
        if (value !== undefined)
            reflectionMergePartial<LostEvent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LostEvent): LostEvent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* flow.LostEventSource source */ 1:
                    message.source = reader.int32();
                    break;
                case /* uint64 num_events_lost */ 2:
                    message.numEventsLost = reader.uint64().toNumber();
                    break;
                case /* google.protobuf.Int32Value cpu */ 3:
                    message.cpu = Int32Value.internalBinaryRead(reader, reader.uint32(), options, message.cpu);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LostEvent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* flow.LostEventSource source = 1; */
        if (message.source !== 0)
            writer.tag(1, WireType.Varint).int32(message.source);
        /* uint64 num_events_lost = 2; */
        if (message.numEventsLost !== 0)
            writer.tag(2, WireType.Varint).uint64(message.numEventsLost);
        /* google.protobuf.Int32Value cpu = 3; */
        if (message.cpu)
            Int32Value.internalBinaryWrite(message.cpu, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message flow.LostEvent
 */
export const LostEvent = new LostEvent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AgentEvent$Type extends MessageType<AgentEvent> {
    constructor() {
        super("flow.AgentEvent", [
            { no: 1, name: "type", kind: "enum", T: () => ["flow.AgentEventType", AgentEventType] },
            { no: 100, name: "unknown", kind: "message", oneof: "notification", T: () => AgentEventUnknown },
            { no: 101, name: "agent_start", kind: "message", oneof: "notification", T: () => TimeNotification },
            { no: 102, name: "policy_update", kind: "message", oneof: "notification", T: () => PolicyUpdateNotification },
            { no: 103, name: "endpoint_regenerate", kind: "message", oneof: "notification", T: () => EndpointRegenNotification },
            { no: 104, name: "endpoint_update", kind: "message", oneof: "notification", T: () => EndpointUpdateNotification },
            { no: 105, name: "ipcache_update", kind: "message", oneof: "notification", T: () => IPCacheNotification },
            { no: 106, name: "service_upsert", kind: "message", oneof: "notification", T: () => ServiceUpsertNotification },
            { no: 107, name: "service_delete", kind: "message", oneof: "notification", T: () => ServiceDeleteNotification }
        ]);
    }
    create(value?: PartialMessage<AgentEvent>): AgentEvent {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.type = 0;
        message.notification = { oneofKind: undefined };
        if (value !== undefined)
            reflectionMergePartial<AgentEvent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AgentEvent): AgentEvent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* flow.AgentEventType type */ 1:
                    message.type = reader.int32();
                    break;
                case /* flow.AgentEventUnknown unknown */ 100:
                    message.notification = {
                        oneofKind: "unknown",
                        unknown: AgentEventUnknown.internalBinaryRead(reader, reader.uint32(), options, (message.notification as any).unknown)
                    };
                    break;
                case /* flow.TimeNotification agent_start */ 101:
                    message.notification = {
                        oneofKind: "agentStart",
                        agentStart: TimeNotification.internalBinaryRead(reader, reader.uint32(), options, (message.notification as any).agentStart)
                    };
                    break;
                case /* flow.PolicyUpdateNotification policy_update */ 102:
                    message.notification = {
                        oneofKind: "policyUpdate",
                        policyUpdate: PolicyUpdateNotification.internalBinaryRead(reader, reader.uint32(), options, (message.notification as any).policyUpdate)
                    };
                    break;
                case /* flow.EndpointRegenNotification endpoint_regenerate */ 103:
                    message.notification = {
                        oneofKind: "endpointRegenerate",
                        endpointRegenerate: EndpointRegenNotification.internalBinaryRead(reader, reader.uint32(), options, (message.notification as any).endpointRegenerate)
                    };
                    break;
                case /* flow.EndpointUpdateNotification endpoint_update */ 104:
                    message.notification = {
                        oneofKind: "endpointUpdate",
                        endpointUpdate: EndpointUpdateNotification.internalBinaryRead(reader, reader.uint32(), options, (message.notification as any).endpointUpdate)
                    };
                    break;
                case /* flow.IPCacheNotification ipcache_update */ 105:
                    message.notification = {
                        oneofKind: "ipcacheUpdate",
                        ipcacheUpdate: IPCacheNotification.internalBinaryRead(reader, reader.uint32(), options, (message.notification as any).ipcacheUpdate)
                    };
                    break;
                case /* flow.ServiceUpsertNotification service_upsert */ 106:
                    message.notification = {
                        oneofKind: "serviceUpsert",
                        serviceUpsert: ServiceUpsertNotification.internalBinaryRead(reader, reader.uint32(), options, (message.notification as any).serviceUpsert)
                    };
                    break;
                case /* flow.ServiceDeleteNotification service_delete */ 107:
                    message.notification = {
                        oneofKind: "serviceDelete",
                        serviceDelete: ServiceDeleteNotification.internalBinaryRead(reader, reader.uint32(), options, (message.notification as any).serviceDelete)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AgentEvent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* flow.AgentEventType type = 1; */
        if (message.type !== 0)
            writer.tag(1, WireType.Varint).int32(message.type);
        /* flow.AgentEventUnknown unknown = 100; */
        if (message.notification.oneofKind === "unknown")
            AgentEventUnknown.internalBinaryWrite(message.notification.unknown, writer.tag(100, WireType.LengthDelimited).fork(), options).join();
        /* flow.TimeNotification agent_start = 101; */
        if (message.notification.oneofKind === "agentStart")
            TimeNotification.internalBinaryWrite(message.notification.agentStart, writer.tag(101, WireType.LengthDelimited).fork(), options).join();
        /* flow.PolicyUpdateNotification policy_update = 102; */
        if (message.notification.oneofKind === "policyUpdate")
            PolicyUpdateNotification.internalBinaryWrite(message.notification.policyUpdate, writer.tag(102, WireType.LengthDelimited).fork(), options).join();
        /* flow.EndpointRegenNotification endpoint_regenerate = 103; */
        if (message.notification.oneofKind === "endpointRegenerate")
            EndpointRegenNotification.internalBinaryWrite(message.notification.endpointRegenerate, writer.tag(103, WireType.LengthDelimited).fork(), options).join();
        /* flow.EndpointUpdateNotification endpoint_update = 104; */
        if (message.notification.oneofKind === "endpointUpdate")
            EndpointUpdateNotification.internalBinaryWrite(message.notification.endpointUpdate, writer.tag(104, WireType.LengthDelimited).fork(), options).join();
        /* flow.IPCacheNotification ipcache_update = 105; */
        if (message.notification.oneofKind === "ipcacheUpdate")
            IPCacheNotification.internalBinaryWrite(message.notification.ipcacheUpdate, writer.tag(105, WireType.LengthDelimited).fork(), options).join();
        /* flow.ServiceUpsertNotification service_upsert = 106; */
        if (message.notification.oneofKind === "serviceUpsert")
            ServiceUpsertNotification.internalBinaryWrite(message.notification.serviceUpsert, writer.tag(106, WireType.LengthDelimited).fork(), options).join();
        /* flow.ServiceDeleteNotification service_delete = 107; */
        if (message.notification.oneofKind === "serviceDelete")
            ServiceDeleteNotification.internalBinaryWrite(message.notification.serviceDelete, writer.tag(107, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message flow.AgentEvent
 */
export const AgentEvent = new AgentEvent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AgentEventUnknown$Type extends MessageType<AgentEventUnknown> {
    constructor() {
        super("flow.AgentEventUnknown", [
            { no: 1, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "notification", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AgentEventUnknown>): AgentEventUnknown {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.type = "";
        message.notification = "";
        if (value !== undefined)
            reflectionMergePartial<AgentEventUnknown>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AgentEventUnknown): AgentEventUnknown {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string type */ 1:
                    message.type = reader.string();
                    break;
                case /* string notification */ 2:
                    message.notification = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AgentEventUnknown, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string type = 1; */
        if (message.type !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.type);
        /* string notification = 2; */
        if (message.notification !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.notification);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message flow.AgentEventUnknown
 */
export const AgentEventUnknown = new AgentEventUnknown$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TimeNotification$Type extends MessageType<TimeNotification> {
    constructor() {
        super("flow.TimeNotification", [
            { no: 1, name: "time", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<TimeNotification>): TimeNotification {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<TimeNotification>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TimeNotification): TimeNotification {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Timestamp time */ 1:
                    message.time = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.time);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TimeNotification, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Timestamp time = 1; */
        if (message.time)
            Timestamp.internalBinaryWrite(message.time, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message flow.TimeNotification
 */
export const TimeNotification = new TimeNotification$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PolicyUpdateNotification$Type extends MessageType<PolicyUpdateNotification> {
    constructor() {
        super("flow.PolicyUpdateNotification", [
            { no: 1, name: "labels", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "revision", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "rule_count", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<PolicyUpdateNotification>): PolicyUpdateNotification {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.labels = [];
        message.revision = 0;
        message.ruleCount = 0;
        if (value !== undefined)
            reflectionMergePartial<PolicyUpdateNotification>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PolicyUpdateNotification): PolicyUpdateNotification {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string labels */ 1:
                    message.labels.push(reader.string());
                    break;
                case /* uint64 revision */ 2:
                    message.revision = reader.uint64().toNumber();
                    break;
                case /* int64 rule_count */ 3:
                    message.ruleCount = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PolicyUpdateNotification, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string labels = 1; */
        for (let i = 0; i < message.labels.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.labels[i]);
        /* uint64 revision = 2; */
        if (message.revision !== 0)
            writer.tag(2, WireType.Varint).uint64(message.revision);
        /* int64 rule_count = 3; */
        if (message.ruleCount !== 0)
            writer.tag(3, WireType.Varint).int64(message.ruleCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message flow.PolicyUpdateNotification
 */
export const PolicyUpdateNotification = new PolicyUpdateNotification$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EndpointRegenNotification$Type extends MessageType<EndpointRegenNotification> {
    constructor() {
        super("flow.EndpointRegenNotification", [
            { no: 1, name: "id", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "labels", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "error", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<EndpointRegenNotification>): EndpointRegenNotification {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.labels = [];
        message.error = "";
        if (value !== undefined)
            reflectionMergePartial<EndpointRegenNotification>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EndpointRegenNotification): EndpointRegenNotification {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 id */ 1:
                    message.id = reader.uint64().toNumber();
                    break;
                case /* repeated string labels */ 2:
                    message.labels.push(reader.string());
                    break;
                case /* string error */ 3:
                    message.error = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EndpointRegenNotification, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).uint64(message.id);
        /* repeated string labels = 2; */
        for (let i = 0; i < message.labels.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.labels[i]);
        /* string error = 3; */
        if (message.error !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.error);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message flow.EndpointRegenNotification
 */
export const EndpointRegenNotification = new EndpointRegenNotification$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EndpointUpdateNotification$Type extends MessageType<EndpointUpdateNotification> {
    constructor() {
        super("flow.EndpointUpdateNotification", [
            { no: 1, name: "id", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "labels", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "error", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "pod_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "namespace", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<EndpointUpdateNotification>): EndpointUpdateNotification {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.labels = [];
        message.error = "";
        message.podName = "";
        message.namespace = "";
        if (value !== undefined)
            reflectionMergePartial<EndpointUpdateNotification>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EndpointUpdateNotification): EndpointUpdateNotification {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 id */ 1:
                    message.id = reader.uint64().toNumber();
                    break;
                case /* repeated string labels */ 2:
                    message.labels.push(reader.string());
                    break;
                case /* string error */ 3:
                    message.error = reader.string();
                    break;
                case /* string pod_name */ 4:
                    message.podName = reader.string();
                    break;
                case /* string namespace */ 5:
                    message.namespace = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EndpointUpdateNotification, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).uint64(message.id);
        /* repeated string labels = 2; */
        for (let i = 0; i < message.labels.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.labels[i]);
        /* string error = 3; */
        if (message.error !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.error);
        /* string pod_name = 4; */
        if (message.podName !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.podName);
        /* string namespace = 5; */
        if (message.namespace !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.namespace);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message flow.EndpointUpdateNotification
 */
export const EndpointUpdateNotification = new EndpointUpdateNotification$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IPCacheNotification$Type extends MessageType<IPCacheNotification> {
    constructor() {
        super("flow.IPCacheNotification", [
            { no: 1, name: "cidr", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "identity", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "old_identity", kind: "message", T: () => UInt32Value },
            { no: 4, name: "host_ip", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "old_host_ip", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "encrypt_key", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 7, name: "namespace", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "pod_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<IPCacheNotification>): IPCacheNotification {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.cidr = "";
        message.identity = 0;
        message.hostIp = "";
        message.oldHostIp = "";
        message.encryptKey = 0;
        message.namespace = "";
        message.podName = "";
        if (value !== undefined)
            reflectionMergePartial<IPCacheNotification>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IPCacheNotification): IPCacheNotification {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string cidr */ 1:
                    message.cidr = reader.string();
                    break;
                case /* uint32 identity */ 2:
                    message.identity = reader.uint32();
                    break;
                case /* google.protobuf.UInt32Value old_identity */ 3:
                    message.oldIdentity = UInt32Value.internalBinaryRead(reader, reader.uint32(), options, message.oldIdentity);
                    break;
                case /* string host_ip */ 4:
                    message.hostIp = reader.string();
                    break;
                case /* string old_host_ip */ 5:
                    message.oldHostIp = reader.string();
                    break;
                case /* uint32 encrypt_key */ 6:
                    message.encryptKey = reader.uint32();
                    break;
                case /* string namespace */ 7:
                    message.namespace = reader.string();
                    break;
                case /* string pod_name */ 8:
                    message.podName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IPCacheNotification, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string cidr = 1; */
        if (message.cidr !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.cidr);
        /* uint32 identity = 2; */
        if (message.identity !== 0)
            writer.tag(2, WireType.Varint).uint32(message.identity);
        /* google.protobuf.UInt32Value old_identity = 3; */
        if (message.oldIdentity)
            UInt32Value.internalBinaryWrite(message.oldIdentity, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string host_ip = 4; */
        if (message.hostIp !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.hostIp);
        /* string old_host_ip = 5; */
        if (message.oldHostIp !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.oldHostIp);
        /* uint32 encrypt_key = 6; */
        if (message.encryptKey !== 0)
            writer.tag(6, WireType.Varint).uint32(message.encryptKey);
        /* string namespace = 7; */
        if (message.namespace !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.namespace);
        /* string pod_name = 8; */
        if (message.podName !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.podName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message flow.IPCacheNotification
 */
export const IPCacheNotification = new IPCacheNotification$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ServiceUpsertNotificationAddr$Type extends MessageType<ServiceUpsertNotificationAddr> {
    constructor() {
        super("flow.ServiceUpsertNotificationAddr", [
            { no: 1, name: "ip", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "port", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<ServiceUpsertNotificationAddr>): ServiceUpsertNotificationAddr {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.ip = "";
        message.port = 0;
        if (value !== undefined)
            reflectionMergePartial<ServiceUpsertNotificationAddr>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ServiceUpsertNotificationAddr): ServiceUpsertNotificationAddr {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string ip */ 1:
                    message.ip = reader.string();
                    break;
                case /* uint32 port */ 2:
                    message.port = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ServiceUpsertNotificationAddr, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string ip = 1; */
        if (message.ip !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.ip);
        /* uint32 port = 2; */
        if (message.port !== 0)
            writer.tag(2, WireType.Varint).uint32(message.port);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message flow.ServiceUpsertNotificationAddr
 */
export const ServiceUpsertNotificationAddr = new ServiceUpsertNotificationAddr$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ServiceUpsertNotification$Type extends MessageType<ServiceUpsertNotification> {
    constructor() {
        super("flow.ServiceUpsertNotification", [
            { no: 1, name: "id", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "frontend_address", kind: "message", T: () => ServiceUpsertNotificationAddr },
            { no: 3, name: "backend_addresses", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ServiceUpsertNotificationAddr },
            { no: 4, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "traffic_policy", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "namespace", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "ext_traffic_policy", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "int_traffic_policy", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ServiceUpsertNotification>): ServiceUpsertNotification {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.backendAddresses = [];
        message.type = "";
        message.trafficPolicy = "";
        message.name = "";
        message.namespace = "";
        message.extTrafficPolicy = "";
        message.intTrafficPolicy = "";
        if (value !== undefined)
            reflectionMergePartial<ServiceUpsertNotification>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ServiceUpsertNotification): ServiceUpsertNotification {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 id */ 1:
                    message.id = reader.uint32();
                    break;
                case /* flow.ServiceUpsertNotificationAddr frontend_address */ 2:
                    message.frontendAddress = ServiceUpsertNotificationAddr.internalBinaryRead(reader, reader.uint32(), options, message.frontendAddress);
                    break;
                case /* repeated flow.ServiceUpsertNotificationAddr backend_addresses */ 3:
                    message.backendAddresses.push(ServiceUpsertNotificationAddr.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string type */ 4:
                    message.type = reader.string();
                    break;
                case /* string traffic_policy = 5 [deprecated = true];*/ 5:
                    message.trafficPolicy = reader.string();
                    break;
                case /* string name */ 6:
                    message.name = reader.string();
                    break;
                case /* string namespace */ 7:
                    message.namespace = reader.string();
                    break;
                case /* string ext_traffic_policy */ 8:
                    message.extTrafficPolicy = reader.string();
                    break;
                case /* string int_traffic_policy */ 9:
                    message.intTrafficPolicy = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ServiceUpsertNotification, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).uint32(message.id);
        /* flow.ServiceUpsertNotificationAddr frontend_address = 2; */
        if (message.frontendAddress)
            ServiceUpsertNotificationAddr.internalBinaryWrite(message.frontendAddress, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated flow.ServiceUpsertNotificationAddr backend_addresses = 3; */
        for (let i = 0; i < message.backendAddresses.length; i++)
            ServiceUpsertNotificationAddr.internalBinaryWrite(message.backendAddresses[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string type = 4; */
        if (message.type !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.type);
        /* string traffic_policy = 5 [deprecated = true]; */
        if (message.trafficPolicy !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.trafficPolicy);
        /* string name = 6; */
        if (message.name !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.name);
        /* string namespace = 7; */
        if (message.namespace !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.namespace);
        /* string ext_traffic_policy = 8; */
        if (message.extTrafficPolicy !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.extTrafficPolicy);
        /* string int_traffic_policy = 9; */
        if (message.intTrafficPolicy !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.intTrafficPolicy);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message flow.ServiceUpsertNotification
 */
export const ServiceUpsertNotification = new ServiceUpsertNotification$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ServiceDeleteNotification$Type extends MessageType<ServiceDeleteNotification> {
    constructor() {
        super("flow.ServiceDeleteNotification", [
            { no: 1, name: "id", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<ServiceDeleteNotification>): ServiceDeleteNotification {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        if (value !== undefined)
            reflectionMergePartial<ServiceDeleteNotification>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ServiceDeleteNotification): ServiceDeleteNotification {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 id */ 1:
                    message.id = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ServiceDeleteNotification, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).uint32(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message flow.ServiceDeleteNotification
 */
export const ServiceDeleteNotification = new ServiceDeleteNotification$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NetworkInterface$Type extends MessageType<NetworkInterface> {
    constructor() {
        super("flow.NetworkInterface", [
            { no: 1, name: "index", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<NetworkInterface>): NetworkInterface {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.index = 0;
        message.name = "";
        if (value !== undefined)
            reflectionMergePartial<NetworkInterface>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NetworkInterface): NetworkInterface {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 index */ 1:
                    message.index = reader.uint32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NetworkInterface, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 index = 1; */
        if (message.index !== 0)
            writer.tag(1, WireType.Varint).uint32(message.index);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message flow.NetworkInterface
 */
export const NetworkInterface = new NetworkInterface$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DebugEvent$Type extends MessageType<DebugEvent> {
    constructor() {
        super("flow.DebugEvent", [
            { no: 1, name: "type", kind: "enum", T: () => ["flow.DebugEventType", DebugEventType] },
            { no: 2, name: "source", kind: "message", T: () => Endpoint },
            { no: 3, name: "hash", kind: "message", T: () => UInt32Value },
            { no: 4, name: "arg1", kind: "message", T: () => UInt32Value },
            { no: 5, name: "arg2", kind: "message", T: () => UInt32Value },
            { no: 6, name: "arg3", kind: "message", T: () => UInt32Value },
            { no: 7, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "cpu", kind: "message", T: () => Int32Value }
        ]);
    }
    create(value?: PartialMessage<DebugEvent>): DebugEvent {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.type = 0;
        message.message = "";
        if (value !== undefined)
            reflectionMergePartial<DebugEvent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DebugEvent): DebugEvent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* flow.DebugEventType type */ 1:
                    message.type = reader.int32();
                    break;
                case /* flow.Endpoint source */ 2:
                    message.source = Endpoint.internalBinaryRead(reader, reader.uint32(), options, message.source);
                    break;
                case /* google.protobuf.UInt32Value hash */ 3:
                    message.hash = UInt32Value.internalBinaryRead(reader, reader.uint32(), options, message.hash);
                    break;
                case /* google.protobuf.UInt32Value arg1 */ 4:
                    message.arg1 = UInt32Value.internalBinaryRead(reader, reader.uint32(), options, message.arg1);
                    break;
                case /* google.protobuf.UInt32Value arg2 */ 5:
                    message.arg2 = UInt32Value.internalBinaryRead(reader, reader.uint32(), options, message.arg2);
                    break;
                case /* google.protobuf.UInt32Value arg3 */ 6:
                    message.arg3 = UInt32Value.internalBinaryRead(reader, reader.uint32(), options, message.arg3);
                    break;
                case /* string message */ 7:
                    message.message = reader.string();
                    break;
                case /* google.protobuf.Int32Value cpu */ 8:
                    message.cpu = Int32Value.internalBinaryRead(reader, reader.uint32(), options, message.cpu);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DebugEvent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* flow.DebugEventType type = 1; */
        if (message.type !== 0)
            writer.tag(1, WireType.Varint).int32(message.type);
        /* flow.Endpoint source = 2; */
        if (message.source)
            Endpoint.internalBinaryWrite(message.source, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.UInt32Value hash = 3; */
        if (message.hash)
            UInt32Value.internalBinaryWrite(message.hash, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.UInt32Value arg1 = 4; */
        if (message.arg1)
            UInt32Value.internalBinaryWrite(message.arg1, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.UInt32Value arg2 = 5; */
        if (message.arg2)
            UInt32Value.internalBinaryWrite(message.arg2, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.UInt32Value arg3 = 6; */
        if (message.arg3)
            UInt32Value.internalBinaryWrite(message.arg3, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* string message = 7; */
        if (message.message !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.message);
        /* google.protobuf.Int32Value cpu = 8; */
        if (message.cpu)
            Int32Value.internalBinaryWrite(message.cpu, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message flow.DebugEvent
 */
export const DebugEvent = new DebugEvent$Type();
